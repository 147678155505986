<div
  class="container"
  [ngClass]="{
    container_final: isCompleted
  }"
>
  <app-section-full
    class="container__section"
    *ngIf="isCompleted"
  >
    <ng-container *ngTemplateOutlet="sectionLeft"></ng-container>
  </app-section-full>
  <app-section-left
    class="container__section container__section_left"
    *ngIf="!isCompleted"
  >
    <ng-container *ngTemplateOutlet="sectionLeft"></ng-container>
  </app-section-left>
  <app-section-right
    class="container__section container__section_right"
    *ngIf="!isCompleted"
  >
    <app-aside
      title="1絵本の内容"
      [books]="order.books"
      [hidePrice]="true"
      [alwaysExpanded]="true"
      (editCartClick)="onEditCartClick()"
    ></app-aside>
  </app-section-right>
  <app-header
    class="container__section container__section_header header"
    [step]="step"
    *ngIf="media.isActive('lt-xl')"
  ></app-header>
</div>

<ng-template #sectionLeft>
  <app-header
    class="header"
    *ngIf="media.isActive('gt-lg')"
  ></app-header>
  <app-main
    class="main"
    *ngIf="!isCompleted"
    (dataChange)="onFormChange($event)"
    (nextClick)="onNextClick()"
    [touchEventEmitter]="touchEventEmitter"
    [isNextDisabled]="isNextDisabled"
    (backClick)="onBackClick()"
    (nextClick)="onNextClick()"
  ></app-main>
  <app-final
    class="main"
    *ngIf="isCompleted"
    [barOrderId]="order.barOrderId"
  ></app-final>
  <app-footer
    class="footer"
    nextText="完了"
  ></app-footer>
</ng-template>
