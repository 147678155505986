<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="reviews"
          ><span [innerHTML]="title | safe: 'html'"></span
        ></lib-image-title>
      </div>
      <div class="container__content">
        <!-- old version -->
        <!-- <div
          class="yotpo yotpo-main-widget"
          [attr.data-product-id]="alias"
          [attr.data-price]="bookPrice?.value"
          data-currency="JPY"
          [attr.data-name]="caption"
          [attr.data-url]="pageUrl"
          [attr.data-image-url]="pageOrigin + '/' + photo"
        ></div> -->
        <div
          class="yotpo-widget-instance"
          data-yotpo-instance-id="965080"
          [attr.data-yotpo-product-id]="alias"
          [attr.data-yotpo-name]="caption"
          [attr.data-yotpo-url]="pageUrl"
          [attr.data-yotpo-image-url]="pageOrigin + '/' + photo"
          [attr.data-yotpo-price]="bookPrice?.value"
          data-yotpo-currency="JPY"
          data-yotpo-description="Product Description"
        ></div>
      </div>
    </div>
  </div>
  <i
    *ngIf="showMonster"
    class="wrap__monster"
  ></i>
</div>
