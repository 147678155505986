import type { AfterViewInit } from '@angular/core';
import { Input, ChangeDetectionStrategy, Component } from '@angular/core';
import { YotpoService } from '../../yotpo.service';

@Component({
  selector: 'app-section-reviews-yotpo-common',
  templateUrl: './section-reviews-yotpo-common.component.html',
  styleUrls: ['./section-reviews-yotpo-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReviewsYotpoCommonComponent implements AfterViewInit {
  /** doesn't support html tags */
  @Input() title: string = 'お客さまの声';

  @Input() showMonster = true;

  constructor(private _yotpo: YotpoService) {}

  ngAfterViewInit(): void {
    this._yotpo.init();
  }
}
