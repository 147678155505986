<app-section-header-gap
  *ngIf="mediaService.isLtXl$ | async"
></app-section-header-gap>
<div class="container">
  <app-banner-one class="container__section container__banner"></app-banner-one>
  <lib-section-features
    class="container__section container__features"
    [featuresData]="featuresData"
  ></lib-section-features>
  <app-section-books-preview
    class="container__section container__books-preview"
  ></app-section-books-preview>
  <!-- <lib-section-reviews
    class="container__section container__reviews"
    [reviews]="reviews"
  ></lib-section-reviews> -->
  <app-section-reviews-yotpo-common
    class="container__section container__reviews"
  ></app-section-reviews-yotpo-common>
  <app-section-about-banner
    class="container__section container__about-banner"
  ></app-section-about-banner>
  <app-section-books
    class="container__section container__books"
    [books]="books"
    [disableCarousel]="true"
  ></app-section-books>
  <lib-section-steps
    class="container__section container__steps"
    [steps]="stepsData"
  ></lib-section-steps>
  <lib-section-faq
    class="container__section container__faq"
    [condensed]="true"
    [faqData]="faqDataCondensed"
    (buttonClick)="onFaqButtonClick()"
  ></lib-section-faq>
  <app-section-footer-banner
    class="container__section container__footer-banner"
  ></app-section-footer-banner>
</div>

<app-scroll-top></app-scroll-top>
