import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { TBookPricesCommon } from '@studiobuki/shared/dist/common/data/books/prices/types';
import { YotpoService } from '../../yotpo.service';

@Component({
  selector: 'app-section-reviews-yotpo-book',
  templateUrl: './section-reviews-yotpo-book.component.html',
  styleUrls: ['./section-reviews-yotpo-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionReviewsYotpoBookComponent<
  Alias extends string,
  Cover extends string,
  ShippingMethod extends string,
  TBookPrices extends TBookPricesCommon<
    Cover,
    ShippingMethod
  > = TBookPricesCommon<Cover, ShippingMethod>,
> implements AfterViewInit
{
  /** supports html tags */
  @Input() title: string = 'お客さまの声';

  @Input() alias!: Alias;

  @Input() caption!: string;

  @Input() prices!: TBookPrices;

  /** relative path */
  @Input() photo!: string;

  @Input() showMonster = true;

  get bookPrice() {
    return this.prices[0]?.[1];
  }

  get pageOrigin() {
    return window.location.origin;
  }

  get pageUrl() {
    return window.location.href;
  }

  constructor(private _yotpo: YotpoService) {}

  ngAfterViewInit(): void {
    this._yotpo.init();
  }
}
