import { Component } from '@angular/core';

@Component({
  selector: 'app-gift-section',
  templateUrl: './gift-section.component.html',
  styleUrls: ['./gift-section.component.scss'],
})
export class GiftSectionComponent {
  public giftArr = [
    {
      imgPath: 'assets/images/about-page-gift-icons/gift-icon_1.png',
      title: 'ご親戚・ご友人への<br>お祝いとして',
    },
    {
      imgPath: 'assets/images/about-page-gift-icons/gift-icon_2.png',
      title: 'ご旅行の思い出として',
    },
    {
      imgPath: 'assets/images/about-page-gift-icons/gift-icon_3.png',
      title: 'クリスマスプレゼント',
    },
    {
      imgPath: 'assets/images/about-page-gift-icons/gift-icon_4.png',
      title: 'お子さまや<br>お孫さまへの<br>お誕生日プレゼント\n',
    },
    {
      imgPath: 'assets/images/about-page-gift-icons/gift-icon_5.png',
      title: '毎日の読み聞かせ用に',
    },
  ];
}
