/* eslint-disable @typescript-eslint/naming-convention */
// here we can implement analytics events triggering functions

import type { TBookId } from '@shared/jp/book/interfaces';
import type {
  TGiftOrderCreated,
  TGiftOrderGenerated,
} from '@shared/jp/gift/interfaces';
import type { IAddressBillingData, IOrderData } from '@shared/jp/interfaces';
import { Logger } from '@shared/common/logger';
import { environment } from 'src/environments/environment';
import type { TStepId } from './app/pages/order-page/interfaces';

const triggerCheck = <FN, ARGS extends any[]>(
  log: Logger,
  fn: FN,
  ...args: ARGS
) => {
  if (typeof fn === 'function') {
    fn(...args);
  } else {
    const level = environment.production ? 'error' : 'info';

    log[level](`trigger doesn't exist, arguments:`, ...args);
  }
};

const gtagTriggerLog = new Logger('gtagTrigger');
export const gtagTrigger: Gtag.Gtag = (...args: any[]) =>
  triggerCheck(gtagTriggerLog, window.gtag, ...args);

const ytagTriggerLog = new Logger('ytagTrigger');
export const ytagTrigger = (...args: any) =>
  triggerCheck(ytagTriggerLog, window.ytag, ...args);

const fbqTriggerLog = new Logger('fbqTrigger');
export const fbqTrigger: facebook.Pixel.Event = (...args: any[]) =>
  triggerCheck(fbqTriggerLog, window.fbq, ...args);

// const _ltTriggerLog = new Logger('_ltTrigger');
// export const _ltTrigger = (...args: any) =>
//   triggerCheck(_ltTriggerLog, window._lt, ...args);

// const pintrkTriggerLog = new Logger('pintrkTrigger');
// export const pintrkTrigger = (...args: any) =>
//   triggerCheck(pintrkTriggerLog, window.pintrk, ...args);

// const uetqTriggerLog = new Logger('uerqTrigger');
// export const uetqTrigger = (...args: any) =>
//   triggerCheck(uetqTriggerLog, window._uetq, ...args);

// * Google Anlytics section

/**
 * set of google analytics events we are waiting for
 */
export enum GAEvents {
  thankyou = 'thankyou',
}

export const GAGetEvents = {
  getBookPreview: (bookId: TBookId) => `book_preview_${bookId}`,
  getAddToCart: (bookId: TBookId) => `add_to_cart_${bookId}`,
  getCheckoutStep: (stepId: TStepId) => `checkout_step_${stepId}`,
};

/**
 * triggers Google Analytics event by
 * ! ga('track', `event`)
 * @param event - one of `FBEvents` values
 */
export const GATrack = (
  event: GAEvents | string,
  eventParams: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams = {},
) =>
  gtagTrigger('event', event, {
    event_category: 'Default',
    send_to: 'G-70WXTN161D',
    ...eventParams,
  });

// * Facebook Pixel section

/**
 * set of facebook pixel events we are waiting for
 */
export enum FBEvents {
  viewContent = 'ViewContent',
  addToCart = 'AddToCart',
  initiateCheckout = 'InitiateCheckout',
  purchase = 'Purchase',
}

/**
 * triggers Facebook Pixel event by
 * fbq('track', `event`)
 * @param event - one of `FBEvents` values
 */
export const FBTrack = (
  event: FBEvents,
  parameters?:
    | facebook.Pixel.ViewContentParameters
    | facebook.Pixel.SearchParameters
    | facebook.Pixel.AddToCartParameters
    | facebook.Pixel.AddToWishlistParameters
    | facebook.Pixel.InitiateCheckoutParameters
    | facebook.Pixel.AddPaymentInfoParameters
    | facebook.Pixel.PurchaseParameters
    | facebook.Pixel.LeadParameters
    | facebook.Pixel.CompleteRegistrationParameters,
) =>
  parameters
    ? fbqTrigger('track', event, parameters)
    : fbqTrigger('track', event);

const sendConversionEvent = (orderData: {
  addressBillingData: IAddressBillingData;
  orderId: string;
}) => {
  try {
    // FBTrack(FBEvents.purchase, {
    //   value: 3900,
    //   currency: 'JPY',
    // });

    // * new approach
    GATrack(GAEvents.thankyou, { transaction_id: orderData.orderId });
    // gtagTrigger('set', 'user_data', {
    //   //  ***Change yourEmailVariable to the actual Javascript variable name where you are storing the user’s email data. Do the same for the other variables below. Make sure the values aren't hashed.
    //   email: orderData.addressBillingData.email,
    //   phone_number: `+81${orderData.addressBillingData.phone.replace(
    //     /(^0|\D)/g,
    //     '',
    //   )}`,
    //   // address: {
    //   //   first_name: orderData.addressBillingData.firstName,
    //   //   last_name: orderData.addressBillingData.lastName,
    //   //   // street: yourStreetAddressVariable,
    //   //   // city: yourCityVariable,
    //   //   region: orderData.addressBillingData.state,
    //   //   postal_code: orderData.addressBillingData.postalCode.replace(/\D/g, ''),
    //   //   country: 'JP',
    //   // },
    // });
    // gtagTrigger('event', 'conversion', {
    //   send_to: 'AW-16565980880/w06wCPzM57AZENCdo9s9',
    //   transaction_id: orderData.orderId,
    // });
    // _ltTrigger(
    //   'send',
    //   'cv',
    //   {
    //     type: 'Conversion',
    //   },
    //   ['1011300d-ebc2-41d8-bbab-99cb084c7a71'],
    // );
    // ytagTrigger({
    //   type: 'yjad_conversion',
    //   config: {
    //     yahoo_ydn_conv_io: 'DScVASdueH8cSqhFk8rmPw..',
    //     yahoo_ydn_conv_label: 'EHQQ3587XYTRGNG1VR1141393',
    //     yahoo_ydn_conv_transaction_id: '',
    //     yahoo_ydn_conv_value: '4200',
    //     yahoo_email: '',
    //     yahoo_phone_number: '',
    //   },
    // });
    // ytagTrigger({
    //   type: 'yss_conversion',
    //   config: {
    //     yahoo_conversion_id: '1001332587',
    //     yahoo_conversion_label: 'kLDACOzqwbsZEJXXk-A9',
    //     yahoo_conversion_value: '4200',
    //   },
    // });

    // const tempErr = console.error;
    // console.error = console.warn;
    // pintrkTrigger('load', '2612978562674', {
    //   em: orderData.addressBillingData.email,
    // });
    // pintrkTrigger('page');
    // console.error = tempErr;

    // pintrkTrigger('track', 'checkout', {
    //   value: 4200,
    //   currency: 'JPY',
    //   order_id: orderData.orderId,
    // });
    // uetqTrigger('event', 'purchase', {});
  } catch (e) {
    console.error(e);
  }
};

export const sendConversionEventByOrder = (orderData: IOrderData) => {
  const { addressBillingData, orderId } = orderData;

  if (!addressBillingData) {
    console.error('!addressBillingData');
    return;
  }

  sendConversionEvent({
    addressBillingData,
    orderId,
  });
};

export const sendConversionEventByGiftOrder = (
  orderId: string,
  giftOrder: TGiftOrderCreated | TGiftOrderGenerated,
) => {
  const { addressBillingData } = giftOrder;

  sendConversionEvent({
    addressBillingData,
    orderId,
  });
};
