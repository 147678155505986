import type { TSectionSteps } from '@studiobuki/web-core/lib/section-steps';
import Books from 'src/app/data/books';

// @ts-ignore
export const GiftBooks = Books;

export const GiftSteps: TSectionSteps = [
  {
    imgSrc: 'assets/images/gift-step-1.png',
    text: ['贈りたい絵本のギフトコードを', '選択し、お会計をします。'],
  },
  {
    imgSrc: 'assets/images/gift-step-2.png',
    text: [
      'メールに記載のギフトコードを',
      '絵本を贈りたい相手にメールやSNS',
      'で送ります。',
    ],
  },
  {
    imgSrc: 'assets/images/gift-step-3.png',
    text: [
      'ギフトコードを受け取った方が',
      'お子さまの情報や住所をを入力して',
      'プレゼント完了です！',
    ],
  },
];
