<div class="container">
  <div class="container__section">
    <app-book-item
      caption="世界にはばたけ！世界を知る旅絵本"
      [photo]="Travel.previewPhoto"
      [summary]="[
        '世界って、広い！面白い！そして、あなたなら大丈夫。これからどんどんその世界を広げていくお子さまに向けた絵本。'
      ]"
      (buttonClick)="onButtonClick(Travel.alias)"
    ></app-book-item>
  </div>
</div>
