import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderGapModule } from 'src/app/shared/section-header-gap/section-header-gap.module';
import { SectionStepsModule } from '@studiobuki/web-core/lib/section-steps';
import { SectionFaqModule } from '@studiobuki/web-core/lib/section-faq';
import { SectionFooterBannerModule } from 'src/app/shared/section-footer-banner/section-footer-banner.module';
import { SectionFooterBannersModule } from 'src/app/shared/section-footer-banners/section-footer-banners.module';
import { ScrollTopModule } from 'src/app/shared/scroll-top/scroll-top.module';
import { YotpoModule } from 'src/app/shared/yotpo/yotpo.module';
import { SectionFeaturesModule } from '@studiobuki/web-core/lib/section-features';
import { SectionAboutBannerModule } from 'src/app/shared/section-about-banner/section-about-banner.module';
import { SectionBooksPreviewModule } from 'src/app/shared/section-books-preview/section-books-preview.module';
import { SectionBooksModule } from 'src/app/shared/section-books/section-books.module';
import { HomePageComponent } from './home-page.component';
import { BannerOneModule } from './components/banner-one/banner-one.module';
import { SectionDescriptionBannerComponent } from './components/section-description-banner/section-description-banner.component';

@NgModule({
  declarations: [HomePageComponent, SectionDescriptionBannerComponent],
  imports: [
    CommonModule,
    SectionHeaderGapModule,
    BannerOneModule,
    SectionFeaturesModule,
    SectionBooksPreviewModule,
    SectionAboutBannerModule,
    SectionBooksModule,
    SectionStepsModule,
    SectionFaqModule,
    SectionFooterBannerModule,
    SectionFooterBannersModule,
    ScrollTopModule,
    YotpoModule,
  ],
})
export class HomePageModule {}
