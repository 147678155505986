/* eslint-disable no-console */
import type { IBookTravelData } from '@studiobuki/shared/dist/jp/models';

import { resolveAssetElement } from '@studiobuki/web-core/lib/book-common';
import type {
  ImageElement,
  TextElement,
  AssetElement,
  TermsWelcomeToTheWold,
  PageTermsWelcomeToTheWold,
  Segment,
  Page,
} from '@studiobuki/web-core/lib/book-common';

import { isNode } from 'browser-or-node';
import type {
  TClothColorTravel,
  TDestinations,
  THairColorTravel,
  THairStyleTravel,
  TKunChan,
  TSkinColorTravel,
  TStrength,
} from '@studiobuki/shared/dist/jp/book/interfaces';
import { KUNCHAN_VALUE_TO_LABEL_MAP } from '@studiobuki/shared/dist/jp/book/maps';
import {
  INPUT_VALUE_DESTINATION_HAWAII,
  INPUT_VALUE_DESTINATION_ENGLAND,
  INPUT_VALUE_DESTINATION_FINLAND,
  INPUT_VALUE_DESTINATION_INDIA,
} from '@studiobuki/shared/dist/jp/book/constants';
import { sortByArray } from '@studiobuki/web-core';
import { jp } from './locale';
import {
  COUNTRY01,
  COUNTRY02,
  FROM1,
  FROM2,
  KEY_KIDS_TITLE,
  KEY_KIDS_TITLE_INDEX,
  STRENGTH,
  STRENGTHA,
  STRENGTHB,
  STRENGTHC,
  STRENGTHD,
  STRENGTHE,
  STRENGTHF,
  STRENGTHF2,
} from './_constants';
import { ZenMaruGothicBold_black_message_14 } from './locale/jp/_styles';

type TSegmentTermsType = TermsWelcomeToTheWold;
type TPageTermsType = PageTermsWelcomeToTheWold;
type TSegment = Segment<
  TSegmentTermsType,
  TPageTermsType,
  IBookTravelData,
  'jp'
>;

const skinColorConfig: {
  [clothersColor in TClothColorTravel]: {
    [skinColor in TSkinColorTravel]:
      | ImageElement
      | AssetElement<{ assetSkin: string }>;
  };
} = {
  green: {
    silver: {
      type: 'AssetElement',
      asset: {
        assetSkin: '01',
      },
    },
    white: {
      type: 'AssetElement',
      asset: {
        assetSkin: '02',
      },
    },
    nutmeg: {
      type: 'AssetElement',
      asset: {
        assetSkin: '03',
      },
    },
    brown: {
      type: 'AssetElement',
      asset: {
        assetSkin: '04',
      },
    },
    black: {
      type: 'AssetElement',
      asset: {
        assetSkin: '05',
      },
    },
  },

  red: {
    silver: {
      type: 'AssetElement',
      asset: {
        assetSkin: '06',
      },
    },
    white: {
      type: 'AssetElement',
      asset: {
        assetSkin: '07',
      },
    },
    nutmeg: {
      type: 'AssetElement',
      asset: {
        assetSkin: '08',
      },
    },
    brown: {
      type: 'AssetElement',
      asset: {
        assetSkin: '09',
      },
    },
    black: {
      type: 'AssetElement',
      asset: {
        assetSkin: '10',
      },
    },
  },
};

const hairColorConfig: {
  [hairColor in THairColorTravel]: {
    [hairStyle in THairStyleTravel]:
      | ImageElement
      | AssetElement<{ assetHair: string }>;
  };
} = {
  silver: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '01',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '02',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '03',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '04',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '05',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '06',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '07',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '08',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '09',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '10',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '11',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '12',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '13',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '14',
      },
    },
  },
  blonde: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '15',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '16',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '17',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '18',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '19',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '20',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '21',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '22',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '23',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '24',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '25',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '26',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '27',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '28',
      },
    },
  },
  red: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '29',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '30',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '31',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '32',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '33',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '34',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '35',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '36',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '37',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '38',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '39',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '40',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '41',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '42',
      },
    },
  },
  brown: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '43',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '44',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '45',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '46',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '47',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '48',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '49',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '50',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '51',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '52',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '53',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '54',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '55',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '56',
      },
    },
  },
  black: {
    shaved: {
      type: 'AssetElement',
      asset: {
        assetHair: '57',
      },
    },
    scarf: {
      type: 'AssetElement',
      asset: {
        assetHair: '58',
      },
    },
    very_short: {
      type: 'AssetElement',
      asset: {
        assetHair: '59',
      },
    },
    short: {
      type: 'AssetElement',
      asset: {
        assetHair: '60',
      },
    },
    short_bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '61',
      },
    },
    long_bang: {
      type: 'AssetElement',
      asset: {
        assetHair: '62',
      },
    },
    medium: {
      type: 'AssetElement',
      asset: {
        assetHair: '63',
      },
    },
    bob: {
      type: 'AssetElement',
      asset: {
        assetHair: '64',
      },
    },
    long: {
      type: 'AssetElement',
      asset: {
        assetHair: '65',
      },
    },
    very_long: {
      type: 'AssetElement',
      asset: {
        assetHair: '66',
      },
    },
    short_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '67',
      },
    },
    long_wave: {
      type: 'AssetElement',
      asset: {
        assetHair: '68',
      },
    },
    short_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '69',
      },
    },
    long_curl: {
      type: 'AssetElement',
      asset: {
        assetHair: '70',
      },
    },
  },
};
const strengthConfig: {
  [strenght in TStrength]:
    | ImageElement
    | AssetElement<{ assetStrength: string }>;
} = {
  kind: {
    type: 'AssetElement',
    asset: {
      assetStrength: '01',
    },
  },
  brave: {
    type: 'AssetElement',
    asset: {
      assetStrength: '02',
    },
  },
  creative: {
    type: 'AssetElement',
    asset: {
      assetStrength: '03',
    },
  },
};

/**
01	Hawaii+England
02	Hawaii+Finalnd
03	Hawaii+India
04	England+Finalnd
05	England+India
06	Finalnd+India
 */
// const destinationsConfig
const destinationsOrder: TDestinations[] = [
  INPUT_VALUE_DESTINATION_HAWAII,
  INPUT_VALUE_DESTINATION_ENGLAND,
  INPUT_VALUE_DESTINATION_FINLAND,
  INPUT_VALUE_DESTINATION_INDIA,
];

const destinationsMap = {
  [`${INPUT_VALUE_DESTINATION_HAWAII}${INPUT_VALUE_DESTINATION_ENGLAND}`]: '01',
  [`${INPUT_VALUE_DESTINATION_HAWAII}${INPUT_VALUE_DESTINATION_FINLAND}`]: '02',
  [`${INPUT_VALUE_DESTINATION_HAWAII}${INPUT_VALUE_DESTINATION_INDIA}`]: '03',
  [`${INPUT_VALUE_DESTINATION_ENGLAND}${INPUT_VALUE_DESTINATION_FINLAND}`]:
    '04',
  [`${INPUT_VALUE_DESTINATION_ENGLAND}${INPUT_VALUE_DESTINATION_INDIA}`]: '05',
  [`${INPUT_VALUE_DESTINATION_FINLAND}${INPUT_VALUE_DESTINATION_INDIA}`]: '06',
} as const;

export const getSortedDestinations = (destinations: TDestinations[]) => {
  const [dest1, dest2] = destinations.sort((a, b) =>
    sortByArray(a, b, destinationsOrder),
  );

  if (!dest1) throw new Error('!dest1');
  if (!dest2) throw new Error('!dest2');

  return [dest1, dest2] as const;
};

const getDestinationsMapValue = (destinations: TDestinations[]) => {
  const [dest1, dest2] = getSortedDestinations(destinations);

  const key = `${dest1}${dest2}` as const;

  if (destinationsMap.hasOwnProperty(key)) {
    return destinationsMap[key as keyof typeof destinationsMap];
  }

  throw new Error(
    `key "${key}" doesn't exist in ${JSON.stringify(destinationsMap, undefined, 2)}`,
  );
};

const ONE_LETTER_KUNCHAN: { [key in TKunChan]: string } = {
  kun: 'a',
  chan: 'b',
  san: 'c',
  nashi: '',
};

const STRENGTH_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしさ',
  brave: 'ゆうかんさ',
  creative: 'はっそうのゆたかさ',
};

const STRENGTHA_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしくて',
  brave: 'ゆうかんで',
  creative: 'はっそうゆたかで',
};
const STRENGTHB_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしさ',
  brave: 'ゆうかんさ',
  creative: 'はっそうりょく',
};
const STRENGTHC_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしい',
  brave: 'ゆうかん',
  creative: 'はっそうゆたか',
};
const STRENGTHD_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしい',
  brave: 'ゆうかんだ',
  creative: 'はっそうゆたかだ',
};

const STRENGTHE_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしさ',
  brave: 'ゆうかんさ',
  creative: 'はっそうりょく',
};

const STRENGTHF_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'やさしい',
  brave: 'ゆうかんな',
  creative: 'はっそうゆたかな',
};
const STRENGTHF2_TO_SHOW: { [key in TStrength]: string } = {
  kind: 'おもいやりをもって　こうどうできる',
  brave: 'こわがらずに　たちむかう',
  creative: 'みんなが　おどろくような　アイデアをもった',
};

const segment12Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '1216',
      pageImageURL: 'assets/templates_jal_travel_book/12016/12016.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/12016/12016.pdf',
      text: [
        jp.SEGMENT_12_16_text_1,
        jp.SEGMENT_12_16_text_2,
        jp.SEGMENT_12_16_text_3,
      ],
      data: {},
    },
  ],
  England: [
    {
      pageId: '1217',
      pageImageURL: 'assets/templates_jal_travel_book/12017/12017.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/12017/12017.pdf',
      text: [
        jp.SEGMENT_12_17_text_1,
        jp.SEGMENT_12_17_text_2,
        jp.SEGMENT_12_17_text_3,
      ],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '1218',
      pageImageURL: 'assets/templates_jal_travel_book/12018/12018.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/12018/12018.pdf',
      text: [
        jp.SEGMENT_12_18_text_1,
        jp.SEGMENT_12_18_text_2,
        jp.SEGMENT_12_18_text_3,
      ],
      data: {},
    },
  ],
  India: [
    {
      pageId: '12018',
      pageImageURL: 'assets/templates_jal_travel_book/12018/12018.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/12018/12018.pdf',
      text: [],
      data: {},
    },
  ],
};
const segment13Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '1319',
      pageImageURL: 'assets/templates_jal_travel_book/13019/13019.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/13019/13019.pdf',
      text: [jp.SEGMENT_13_19_text_1],
      data: {},
    },
  ],
  England: [
    {
      pageId: '1319',
      pageImageURL: 'assets/templates_jal_travel_book/13020/13020.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/13020/13020.pdf',
      text: [jp.SEGMENT_13_19_text_1],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '1320',
      pageImageURL: 'assets/templates_jal_travel_book/13021/13021.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/13021/13021.pdf',
      text: [jp.SEGMENT_13_20_text_1],
      data: {},
    },
  ],
  India: [
    {
      pageId: '1321',
      pageImageURL: 'assets/templates_jal_travel_book/13021/13021.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/13021/13021.pdf',
      text: [jp.SEGMENT_13_21_text_1],
      data: {},
    },
  ],
};

const segment14Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99031',
      pageImageURL: 'assets/templates_jal_travel_book/99031/99031.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99031/99031.pdf',
      text: [jp.SEGMENT_14_99031_text_1, jp.SEGMENT_14_99031_text_2],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99041',
      pageImageURL: 'assets/templates_jal_travel_book/99041/99041.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99041/99041.pdf',
      text: [jp.SEGMENT_14_99041_text_1, jp.SEGMENT_14_99041_text_2],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99051',
      pageImageURL: 'assets/templates_jal_travel_book/99051/99051.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99051/99051.pdf',
      text: [jp.SEGMENT_14_99051_text_1, jp.SEGMENT_14_99051_text_2],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99061',
      pageImageURL: 'assets/templates_jal_travel_book/99061/99061.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99061/99061.pdf',
      text: [],
      data: {},
    },
  ],
};
const segment15Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99032',
      pageImageURL: 'assets/templates_jal_travel_book/99032/99032.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99032/99032.pdf',
      text: [jp.SEGMENT_15_99032_text_1, jp.SEGMENT_15_99032_text_2],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99042',
      pageImageURL: 'assets/templates_jal_travel_book/99042/99042.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99042/99042.pdf',
      text: [jp.SEGMENT_15_99042_text_1, jp.SEGMENT_15_99042_text_2],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99052',
      pageImageURL: 'assets/templates_jal_travel_book/99052/99052.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99052/99052.pdf',
      text: [
        jp.SEGMENT_15_99052_text_1,
        jp.SEGMENT_15_99052_text_2,
        jp.SEGMENT_15_99052_text_3,
      ],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99062',
      pageImageURL: 'assets/templates_jal_travel_book/99062/99062.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99062/99062.pdf',
      text: [],
      data: {},
    },
  ],
};
const segment16Pages: {
  [key in TDestinations]: {
    [_key in TStrength]: Page<TPageTermsType>[];
  };
} = {
  Hawaii: {
    kind: [
      {
        pageId: '99033',
        pageImageURL: 'assets/templates_jal_travel_book/99033/99033.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99033/99033.pdf',
        text: [
          jp.SEGMENT_16_99033_text_1,
          jp.SEGMENT_16_99033_text_2,
          jp.SEGMENT_16_99033_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99035',
        pageImageURL: 'assets/templates_jal_travel_book/99035/99035.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99035/99035.pdf',
        text: [jp.SEGMENT_16_99035_text_1, jp.SEGMENT_16_99035_text_2],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99037',
        pageImageURL: 'assets/templates_jal_travel_book/99037/99037.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99037/99037.pdf',
        text: [
          jp.SEGMENT_16_99037_text_1,
          jp.SEGMENT_16_99037_text_2,
          jp.SEGMENT_16_99037_text_3,
          jp.SEGMENT_16_99037_text_4,
        ],
        data: {},
      },
    ],
  },
  England: {
    kind: [
      {
        pageId: '99043',
        pageImageURL: 'assets/templates_jal_travel_book/99043/99043.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99043/99043.pdf',
        text: [
          jp.SEGMENT_16_99043_text_1,
          jp.SEGMENT_16_99043_text_2,
          jp.SEGMENT_16_99043_text_3,
          jp.SEGMENT_16_99043_text_4,
          jp.SEGMENT_16_99043_text_5,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99045',
        pageImageURL: 'assets/templates_jal_travel_book/99045/99045.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99045/99045.pdf',
        text: [
          jp.SEGMENT_16_99045_text_1,
          jp.SEGMENT_16_99045_text_2,
          jp.SEGMENT_16_99045_text_3,
          jp.SEGMENT_16_99045_text_4,
          jp.SEGMENT_16_99045_text_5,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99047',
        pageImageURL: 'assets/templates_jal_travel_book/99047/99047.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99047/99047.pdf',
        text: [
          jp.SEGMENT_16_99047_text_1,
          jp.SEGMENT_16_99047_text_2,
          jp.SEGMENT_16_99047_text_3,
        ],
        data: {},
      },
    ],
  },
  Finland: {
    kind: [
      {
        pageId: '99053',
        pageImageURL: 'assets/templates_jal_travel_book/99053/99053.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99053/99053.pdf',
        text: [
          jp.SEGMENT_16_99053_text_1,
          jp.SEGMENT_16_99053_text_2,
          jp.SEGMENT_16_99053_text_3,
          jp.SEGMENT_16_99053_text_4,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99055',
        pageImageURL: 'assets/templates_jal_travel_book/99055/99055.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99055/99055.pdf',
        text: [
          jp.SEGMENT_16_99055_text_1,
          jp.SEGMENT_16_99055_text_2,
          jp.SEGMENT_16_99055_text_3,
          jp.SEGMENT_16_99055_text_4,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99057',
        pageImageURL: 'assets/templates_jal_travel_book/99057/99057.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99057/99057.pdf',
        text: [
          jp.SEGMENT_16_99057_text_1,
          jp.SEGMENT_16_99057_text_2,
          jp.SEGMENT_16_99057_text_3,
          jp.SEGMENT_16_99057_text_4,
        ],
        data: {},
      },
    ],
  },
  India: {
    kind: [
      {
        pageId: '0',
        pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
        text: [],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99032',
        pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
        text: [],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99032',
        pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
        text: [],
        data: {},
      },
    ],
  },
};

const segment17Pages: {
  [key in TDestinations]: {
    [_key in TStrength]: Page<TPageTermsType>[];
  };
} = {
  Hawaii: {
    kind: [
      {
        pageId: '99034',
        pageImageURL: 'assets/templates_jal_travel_book/99034/99034.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99034/99034.pdf',
        text: [
          jp.SEGMENT_17_99034_text_1,
          jp.SEGMENT_17_99034_text_2,
          jp.SEGMENT_17_99034_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99036',
        pageImageURL: 'assets/templates_jal_travel_book/99036/99036.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99036/99036.pdf',
        text: [
          jp.SEGMENT_17_99036_text_1,
          jp.SEGMENT_17_99036_text_2,
          jp.SEGMENT_17_99036_text_3,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99038',
        pageImageURL: 'assets/templates_jal_travel_book/99038/99038.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99038/99038.pdf',
        text: [
          jp.SEGMENT_17_99038_text_1,
          jp.SEGMENT_17_99038_text_2,
          jp.SEGMENT_17_99038_text_3,
        ],
        data: {},
      },
    ],
  },
  England: {
    kind: [
      {
        pageId: '99044',
        pageImageURL: 'assets/templates_jal_travel_book/99044/99044.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99044/99044.pdf',
        text: [
          jp.SEGMENT_17_99044_text_1,
          jp.SEGMENT_17_99044_text_2,
          jp.SEGMENT_17_99044_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99046',
        pageImageURL: 'assets/templates_jal_travel_book/99046/99046.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99046/99046.pdf',
        text: [
          jp.SEGMENT_17_99046_text_1,
          jp.SEGMENT_17_99046_text_2,
          jp.SEGMENT_17_99046_text_3,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99048',
        pageImageURL: 'assets/templates_jal_travel_book/99048/99048.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99048/99048.pdf',
        text: [
          jp.SEGMENT_17_99048_text_1,
          jp.SEGMENT_17_99048_text_2,
          jp.SEGMENT_17_99048_text_3,
          jp.SEGMENT_17_99048_text_4,
        ],
        data: {},
      },
    ],
  },
  Finland: {
    kind: [
      {
        pageId: '99054',
        pageImageURL: 'assets/templates_jal_travel_book/99054/99054.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99054/99054.pdf',
        text: [
          jp.SEGMENT_17_99054_text_1,
          jp.SEGMENT_17_99054_text_2,
          jp.SEGMENT_17_99054_text_3,
          jp.SEGMENT_17_99054_text_4,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99056',
        pageImageURL: 'assets/templates_jal_travel_book/99056/99056.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99056/99056.pdf',
        text: [
          jp.SEGMENT_17_99056_text_1,
          jp.SEGMENT_17_99056_text_2,
          jp.SEGMENT_17_99056_text_3,
          jp.SEGMENT_17_99056_text_4,
          jp.SEGMENT_17_99056_text_5,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99058',
        pageImageURL: 'assets/templates_jal_travel_book/99058/99058.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99058/99058.pdf',
        text: [
          jp.SEGMENT_17_99058_text_1,
          jp.SEGMENT_17_99058_text_2,
          jp.SEGMENT_17_99058_text_3,
          jp.SEGMENT_17_99058_text_4,
        ],
        data: {},
      },
    ],
  },
  India: {
    kind: [
      {
        pageId: '0',
        pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
        text: [],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99032',
        pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
        text: [],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99032',
        pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
        text: [],
        data: {},
      },
    ],
  },
};

const segment18Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99039',
      pageImageURL: 'assets/templates_jal_travel_book/99039/99039.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99039/99039.pdf',
      text: [
        jp.SEGMENT_18_99039_text_1,
        jp.SEGMENT_18_99039_text_2,
        jp.SEGMENT_18_99039_text_3,
      ],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99049',
      pageImageURL: 'assets/templates_jal_travel_book/99049/99049.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99049/99049.pdf',
      text: [
        jp.SEGMENT_18_99049_text_1,
        jp.SEGMENT_18_99049_text_2,
        jp.SEGMENT_18_99049_text_3,
        jp.SEGMENT_18_99049_text_4,
      ],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99059',
      pageImageURL: 'assets/templates_jal_travel_book/99059/99059.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99059/99059.pdf',
      text: [jp.SEGMENT_18_99059_text_1, jp.SEGMENT_18_99059_text_2],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99069',
      pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
      text: [],
      data: {},
    },
  ],
};
const segment19Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99040',
      pageImageURL: 'assets/templates_jal_travel_book/99040/99040.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99040/99040.pdf',
      text: [jp.SEGMENT_19_99040_text_1, jp.SEGMENT_19_99040_text_2],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99050',
      pageImageURL: 'assets/templates_jal_travel_book/99050/99050.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99050/99050.pdf',
      text: [jp.SEGMENT_19_99050_text_1, jp.SEGMENT_19_99050_text_2],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99060',
      pageImageURL: 'assets/templates_jal_travel_book/99060/99060.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99060/99060.pdf',
      text: [jp.SEGMENT_19_99060_text_1, jp.SEGMENT_19_99060_text_2],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99070',
      pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
      text: [],
      data: {},
    },
  ],
};

const segment22Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '0',
      pageImageURL: 'assets/templates_jal_travel_book/0/0.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/0/0.pdf',
      text: [],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99041',
      pageImageURL: 'assets/templates_jal_travel_book/99041/99041.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99041/99041.pdf',
      text: [jp.SEGMENT_14_99041_text_1, jp.SEGMENT_14_99041_text_2],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99051',
      pageImageURL: 'assets/templates_jal_travel_book/99051/99051.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99051/99051.pdf',
      text: [jp.SEGMENT_14_99051_text_1, jp.SEGMENT_14_99051_text_2],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99061',
      pageImageURL: 'assets/templates_jal_travel_book/99061/99061.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99061/99061.pdf',
      text: [jp.SEGMENT_22_99061_text_1, jp.SEGMENT_22_99061_text_2],
      data: {},
    },
  ],
};

const segment23Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99032',
      pageImageURL: 'assets/templates_jal_travel_book/99032/99032.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99032/99032.pdf',
      text: [jp.SEGMENT_15_99032_text_1, jp.SEGMENT_15_99032_text_2],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99042',
      pageImageURL: 'assets/templates_jal_travel_book/99042/99042.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99042/99042.pdf',
      text: [jp.SEGMENT_15_99042_text_1, jp.SEGMENT_15_99042_text_2],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99052',
      pageImageURL: 'assets/templates_jal_travel_book/99052/99052.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99052/99052.pdf',
      text: [
        jp.SEGMENT_15_99052_text_1,
        jp.SEGMENT_15_99052_text_2,
        jp.SEGMENT_15_99052_text_3,
      ],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99062',
      pageImageURL: 'assets/templates_jal_travel_book/99062/99062.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99062/99062.pdf',
      text: [
        jp.SEGMENT_23_99062_text_1,
        jp.SEGMENT_23_99062_text_2,
        jp.SEGMENT_23_99062_text_3,
      ],
      data: {},
    },
  ],
};

const segment24Pages: {
  [key in TDestinations]: {
    [_key in TStrength]: Page<TPageTermsType>[];
  };
} = {
  Hawaii: {
    kind: [
      {
        pageId: '99033',
        pageImageURL: 'assets/templates_jal_travel_book/99033/99033.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99033/99033.pdf',
        text: [
          jp.SEGMENT_16_99033_text_1,
          jp.SEGMENT_16_99033_text_2,
          jp.SEGMENT_16_99033_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99035',
        pageImageURL: 'assets/templates_jal_travel_book/99035/99035.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99035/99035.pdf',
        text: [jp.SEGMENT_16_99035_text_1, jp.SEGMENT_16_99035_text_2],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99037',
        pageImageURL: 'assets/templates_jal_travel_book/99037/99037.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99037/99037.pdf',
        text: [
          jp.SEGMENT_16_99037_text_1,
          jp.SEGMENT_16_99037_text_2,
          jp.SEGMENT_16_99037_text_3,
          jp.SEGMENT_16_99037_text_4,
        ],
        data: {},
      },
    ],
  },
  England: {
    kind: [
      {
        pageId: '99043',
        pageImageURL: 'assets/templates_jal_travel_book/99043/99043.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99043/99043.pdf',
        text: [
          jp.SEGMENT_16_99043_text_1,
          jp.SEGMENT_16_99043_text_2,
          jp.SEGMENT_16_99043_text_3,
          jp.SEGMENT_16_99043_text_4,
          jp.SEGMENT_16_99043_text_5,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99045',
        pageImageURL: 'assets/templates_jal_travel_book/99045/99045.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99045/99045.pdf',
        text: [
          jp.SEGMENT_16_99045_text_1,
          jp.SEGMENT_16_99045_text_2,
          jp.SEGMENT_16_99045_text_3,
          jp.SEGMENT_16_99045_text_4,
          jp.SEGMENT_16_99045_text_5,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99047',
        pageImageURL: 'assets/templates_jal_travel_book/99047/99047.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99047/99047.pdf',
        text: [
          jp.SEGMENT_16_99047_text_1,
          jp.SEGMENT_16_99047_text_2,
          jp.SEGMENT_16_99047_text_3,
        ],
        data: {},
      },
    ],
  },
  Finland: {
    kind: [
      {
        pageId: '99053',
        pageImageURL: 'assets/templates_jal_travel_book/99053/99053.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99053/99053.pdf',
        text: [
          jp.SEGMENT_16_99053_text_1,
          jp.SEGMENT_16_99053_text_2,
          jp.SEGMENT_16_99053_text_3,
          jp.SEGMENT_16_99053_text_4,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99055',
        pageImageURL: 'assets/templates_jal_travel_book/99055/99055.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99055/99055.pdf',
        text: [
          jp.SEGMENT_16_99055_text_1,
          jp.SEGMENT_16_99055_text_2,
          jp.SEGMENT_16_99055_text_3,
          jp.SEGMENT_16_99055_text_4,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99057',
        pageImageURL: 'assets/templates_jal_travel_book/99057/99057.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99057/99057.pdf',
        text: [
          jp.SEGMENT_16_99057_text_1,
          jp.SEGMENT_16_99057_text_2,
          jp.SEGMENT_16_99057_text_3,
          jp.SEGMENT_16_99057_text_4,
        ],
        data: {},
      },
    ],
  },
  India: {
    kind: [
      {
        pageId: '99063',
        pageImageURL: 'assets/templates_jal_travel_book/99063/99063.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99063/99063.pdf',
        text: [
          jp.SEGMENT_24_99063_text_1,
          jp.SEGMENT_24_99063_text_2,
          jp.SEGMENT_24_99063_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99065',
        pageImageURL: 'assets/templates_jal_travel_book/99065/99065.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99065/99065.pdf',
        text: [jp.SEGMENT_24_99065_text_1, jp.SEGMENT_24_99065_text_2],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99067',
        pageImageURL: 'assets/templates_jal_travel_book/99067/99067.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99067/99067.pdf',
        text: [
          jp.SEGMENT_24_99067_text_1,
          jp.SEGMENT_24_99067_text_2,
          jp.SEGMENT_24_99067_text_3,
          jp.SEGMENT_24_99067_text_4,
        ],
        data: {},
      },
    ],
  },
};

const segment25Pages: {
  [key in TDestinations]: {
    [_key in TStrength]: Page<TPageTermsType>[];
  };
} = {
  Hawaii: {
    kind: [
      {
        pageId: '99034',
        pageImageURL: 'assets/templates_jal_travel_book/99034/99034.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99034/99034.pdf',
        text: [
          jp.SEGMENT_17_99034_text_1,
          jp.SEGMENT_17_99034_text_2,
          jp.SEGMENT_17_99034_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99036',
        pageImageURL: 'assets/templates_jal_travel_book/99036/99036.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99036/99036.pdf',
        text: [
          jp.SEGMENT_17_99036_text_1,
          jp.SEGMENT_17_99036_text_2,
          jp.SEGMENT_17_99036_text_3,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99038',
        pageImageURL: 'assets/templates_jal_travel_book/99038/99038.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99038/99038.pdf',
        text: [
          jp.SEGMENT_17_99038_text_1,
          jp.SEGMENT_17_99038_text_2,
          jp.SEGMENT_17_99038_text_3,
        ],
        data: {},
      },
    ],
  },
  England: {
    kind: [
      {
        pageId: '99044',
        pageImageURL: 'assets/templates_jal_travel_book/99044/99044.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99044/99044.pdf',
        text: [
          jp.SEGMENT_17_99044_text_1,
          jp.SEGMENT_17_99044_text_2,
          jp.SEGMENT_17_99044_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99046',
        pageImageURL: 'assets/templates_jal_travel_book/99046/99046.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99046/99046.pdf',
        text: [
          jp.SEGMENT_17_99046_text_1,
          jp.SEGMENT_17_99046_text_2,
          jp.SEGMENT_17_99046_text_3,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99048',
        pageImageURL: 'assets/templates_jal_travel_book/99048/99048.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99048/99048.pdf',
        text: [
          jp.SEGMENT_17_99048_text_1,
          jp.SEGMENT_17_99048_text_2,
          jp.SEGMENT_17_99048_text_3,
          jp.SEGMENT_17_99048_text_4,
        ],
        data: {},
      },
    ],
  },
  Finland: {
    kind: [
      {
        pageId: '99054',
        pageImageURL: 'assets/templates_jal_travel_book/99054/99054.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99054/99054.pdf',
        text: [
          jp.SEGMENT_17_99054_text_1,
          jp.SEGMENT_17_99054_text_2,
          jp.SEGMENT_17_99054_text_3,
          jp.SEGMENT_17_99054_text_4,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99056',
        pageImageURL: 'assets/templates_jal_travel_book/99056/99056.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99056/99056.pdf',
        text: [
          jp.SEGMENT_17_99056_text_1,
          jp.SEGMENT_17_99056_text_2,
          jp.SEGMENT_17_99056_text_3,
          jp.SEGMENT_17_99056_text_4,
          jp.SEGMENT_17_99056_text_5,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99058',
        pageImageURL: 'assets/templates_jal_travel_book/99058/99058.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99058/99058.pdf',
        text: [
          jp.SEGMENT_17_99058_text_1,
          jp.SEGMENT_17_99058_text_2,
          jp.SEGMENT_17_99058_text_3,
          jp.SEGMENT_17_99058_text_4,
        ],
        data: {},
      },
    ],
  },
  India: {
    kind: [
      {
        pageId: '99064',
        pageImageURL: 'assets/templates_jal_travel_book/99064/99064.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99064/99064.pdf',
        text: [
          jp.SEGMENT_25_99064_text_1,
          jp.SEGMENT_25_99064_text_2,
          jp.SEGMENT_25_99064_text_3,
        ],
        data: {},
      },
    ],
    brave: [
      {
        pageId: '99066',
        pageImageURL: 'assets/templates_jal_travel_book/99066/99066.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99066/99066.pdf',
        text: [
          jp.SEGMENT_25_99066_text_1,
          jp.SEGMENT_25_99066_text_2,
          jp.SEGMENT_25_99066_text_3,
          jp.SEGMENT_25_99066_text_4,
        ],
        data: {},
      },
    ],
    creative: [
      {
        pageId: '99068',
        pageImageURL: 'assets/templates_jal_travel_book/99068/99068.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/99068/99068.pdf',
        text: [
          jp.SEGMENT_25_99068_text_1,
          jp.SEGMENT_25_99068_text_2,
          jp.SEGMENT_25_99068_text_3,
          jp.SEGMENT_25_99068_text_4,
        ],
        data: {},
      },
    ],
  },
};

const segment26Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99039',
      pageImageURL: 'assets/templates_jal_travel_book/99039/99039.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99039/99039.pdf',
      text: [
        jp.SEGMENT_18_99039_text_1,
        jp.SEGMENT_18_99039_text_2,
        jp.SEGMENT_18_99039_text_3,
      ],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99049',
      pageImageURL: 'assets/templates_jal_travel_book/99049/99049.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99049/99049.pdf',
      text: [
        jp.SEGMENT_18_99049_text_1,
        jp.SEGMENT_18_99049_text_2,
        jp.SEGMENT_18_99049_text_3,
        jp.SEGMENT_18_99049_text_4,
      ],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99059',
      pageImageURL: 'assets/templates_jal_travel_book/99059/99059.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99059/99059.pdf',
      text: [jp.SEGMENT_18_99059_text_1, jp.SEGMENT_18_99059_text_2],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99069',
      pageImageURL: 'assets/templates_jal_travel_book/99069/99069.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99069/99069.pdf',
      text: [jp.SEGMENT_26_99069_text_1, jp.SEGMENT_26_99069_text_2],
      data: {},
    },
  ],
};

const segment27Pages: {
  [key in TDestinations]: Page<TPageTermsType>[];
} = {
  Hawaii: [
    {
      pageId: '99040',
      pageImageURL: 'assets/templates_jal_travel_book/99040/99040.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99040/99040.pdf',
      text: [jp.SEGMENT_19_99040_text_1, jp.SEGMENT_19_99040_text_2],
      data: {},
    },
  ],
  England: [
    {
      pageId: '99050',
      pageImageURL: 'assets/templates_jal_travel_book/99050/99050.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99050/99050.pdf',
      text: [jp.SEGMENT_19_99050_text_1, jp.SEGMENT_19_99050_text_2],
      data: {},
    },
  ],
  Finland: [
    {
      pageId: '99060',
      pageImageURL: 'assets/templates_jal_travel_book/99060/99060.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99060/99060.pdf',
      text: [jp.SEGMENT_19_99060_text_1, jp.SEGMENT_19_99060_text_2],
      data: {},
    },
  ],
  India: [
    {
      pageId: '99070',
      pageImageURL: 'assets/templates_jal_travel_book/99070/99070.jpg',
      pageImagePDFURL: 'assets/templates_jal_travel_book/99070/99070.pdf',
      text: [
        jp.SEGMENT_27_99070_text_1,
        jp.SEGMENT_27_99070_text_2,
        jp.SEGMENT_27_99070_text_3,
      ],
      data: {},
    },
  ],
};

export const textProcessor = (
  bookData: IBookTravelData,
  _textStr: string,
): string => {
  let textStr = _textStr;
  let country1JP = '';
  let country2JP = '';
  let from1JP = '';
  let from2JP = '';
  let strenthJP = '';
  const country1 = bookData.destinations[0] as string;
  const country2 = bookData.destinations[1] as string;
  const strenth = bookData.strength;

  textStr = textStr.replace(
    new RegExp(`${KEY_KIDS_TITLE_INDEX}`, 'gi'),
    ONE_LETTER_KUNCHAN[bookData.kunChan],
  );

  if (bookData.kunChan !== 'nashi') {
    textStr = textStr.replace(
      new RegExp(`${KEY_KIDS_TITLE}`, 'gi'),
      KUNCHAN_VALUE_TO_LABEL_MAP[bookData.kunChan],
    );
  } else {
    textStr = textStr.replace(new RegExp(`${KEY_KIDS_TITLE}`, 'gi'), '');
  }
  textStr = textStr.replace(
    new RegExp(`${STRENGTH}`, 'gi'),
    STRENGTH_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHA}`, 'gi'),
    STRENGTHA_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHB}`, 'gi'),
    STRENGTHB_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHC}`, 'gi'),
    STRENGTHC_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHD}`, 'gi'),
    STRENGTHD_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHE}`, 'gi'),
    STRENGTHE_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHF}`, 'gi'),
    STRENGTHF_TO_SHOW[bookData.strength],
  );
  textStr = textStr.replace(
    new RegExp(`${STRENGTHF2}`, 'gi'),
    STRENGTHF2_TO_SHOW[bookData.strength],
  );

  switch (country1) {
    case 'Hawaii':
      country1JP = 'ハワイのなみのおと';
      from1JP = 'マノ';
      break;
    case 'England':
      country1JP = 'ロンドンのにぎやかさ';
      from1JP = 'アンナ';
      break;
    case 'Finland':
      country1JP = 'フィンランドのつめたくすんだくうき';
      from1JP = 'ニコ';
      break;
    case 'India':
      country1JP = 'インドのしげきてきなかおり';
      break;
    default:
      console.log(`${country1}  = country1 error`);
      break;
  }
  switch (country2) {
    case 'Hawaii':
      country2JP = 'ハワイのなみのおと';
      break;
    case 'England':
      country2JP = 'ロンドンのにぎやかさ';
      from2JP = 'アンナ';
      break;
    case 'Finland':
      country2JP = 'フィンランドのつめたくすんだくうき';
      from2JP = 'ニコ';
      break;
    case 'India':
      country2JP = 'インドのしげきてきなかおり';
      from2JP = 'ダーシャ';
      break;
    default:
      console.log(`${country2}  = country2 error`);
      break;
  }

  switch (strenth) {
    case 'kind':
      strenthJP = 'やさしい';
      break;
    case 'brave':
      strenthJP = 'ゆうかんな';
      break;
    case 'creative':
      strenthJP = 'はっそうゆたかな';
      break;

    default:
      console.log(`${strenth}  = strenth error`);
      break;
  }

  textStr = textStr.replace(new RegExp(`${COUNTRY01}`, 'gi'), country1JP);
  textStr = textStr.replace(new RegExp(`${COUNTRY02}`, 'gi'), country2JP);
  textStr = textStr.replace(new RegExp(`${FROM1}`, 'gi'), from1JP);
  textStr = textStr.replace(new RegExp(`${FROM2}`, 'gi'), from2JP);
  // textStr = textStr.replace(new RegExp(`${STRENGTHF}`, 'gi'), strenthJP);
  // textStr = textStr.replace(new RegExp(`${STRENGTHF2}`, 'gi'), strenthJP);

  return textStr;
};

const segments = [
  {
    segmentId: '0',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 9,
        y: 0,
        imageURL:
          'assets/templates_jal_travel_book/00002/M/00002-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/00002/M/00002-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 9,
        y: 0,
        imageURL:
          'assets/templates_jal_travel_book/00002/H/00002-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/00002/H/00002-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 9,
        y: 0,
        imageURL: 'assets/templates_jal_travel_book/00002/G/00002-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/00002/G/00002-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '0',
        pageImageURL: 'assets/templates_jal_travel_book/00002/00002.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/00003/00003.pdf',
        data: {
          pageImageHardPDFURL:
            'assets/templates_jal_travel_book/00004/00004.pdf',
        },
        text: [jp.SEGMENT_0_text_1, jp.SEGMENT_0_text_2],
      },
    ],
  } as TSegment,
  {
    segmentId: '1',
    pages: [
      {
        pageId: '1',
        pageImageURL: 'assets/templates_jal_travel_book/01005/01005.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/01005/01005.pdf',

        data: {
          messageRect: {
            x: 176,
            y: 144,
            xPdf: 176,
            yPdf: 144,
            width: isNode ? 410 : 410,
            height: 307,
            style: ZenMaruGothicBold_black_message_14,
          },
        },
      },
    ],
  } as TSegment,
  {
    segmentId: '2',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 352,
        y: 130,
        imageURL:
          'assets/templates_jal_travel_book/02006/M/02006-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/02006/M/02006-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 352,
        y: 130,
        imageURL:
          'assets/templates_jal_travel_book/02006/H/02006-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/02006/H/02006-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 352,
        y: 130,
        imageURL: 'assets/templates_jal_travel_book/02006/G/02006-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/02006/G/02006-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '2',
        pageImageURL: 'assets/templates_jal_travel_book/02006/02006.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/02006/02006.pdf',
        text: [jp.SEGMENT_2_text_1, jp.SEGMENT_2_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '3',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 313,
        y: 204,
        imageURL:
          'assets/templates_jal_travel_book/03007/M/03007-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/03007/M/03007-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 313,
        y: 204,
        imageURL:
          'assets/templates_jal_travel_book/03007/H/03007-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/03007/H/03007-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 313,
        y: 204,
        imageURL: 'assets/templates_jal_travel_book/03007/G/03007-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/03007/G/03007-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '3',
        pageImageURL: 'assets/templates_jal_travel_book/03007/03007.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/03007/03007.pdf',
        text: [jp.SEGMENT_3_text_1, jp.SEGMENT_3_text_2, jp.SEGMENT_3_text_3],
        data: {},
      },
    ],
  } as TSegment,

  {
    segmentId: '4',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 80,
        y: 235,
        imageURL:
          'assets/templates_jal_travel_book/04008/M/04008-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/04008/M/04008-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 80,
        y: 235,
        imageURL:
          'assets/templates_jal_travel_book/04008/H/04008-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/04008/H/04008-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 80,
        y: 235,
        imageURL: 'assets/templates_jal_travel_book/04008/G/04008-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/04008/G/04008-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '4',
        pageImageURL: 'assets/templates_jal_travel_book/04008/04008.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/04008/04008.pdf',
        text: [jp.SEGMENT_4_text_1],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '5',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '5',
        pageImageURL: 'assets/templates_jal_travel_book/05009/05009.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/05009/05009.pdf',
        text: [jp.SEGMENT_5_text_1, jp.SEGMENT_5_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '6',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: -1,
        y: 65,
        imageURL:
          'assets/templates_jal_travel_book/06010/M/06010-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/06010/M/06010-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: -1,
        y: 65,
        imageURL:
          'assets/templates_jal_travel_book/06010/H/06010-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/06010/H/06010-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: -1,
        y: 65,
        imageURL: 'assets/templates_jal_travel_book/06010/G/06010-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/06010/G/06010-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '6',
        pageImageURL: 'assets/templates_jal_travel_book/06010/06010.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/06010/06010.pdf',
        text: [jp.SEGMENT_6_text_1, jp.SEGMENT_6_text_2, jp.SEGMENT_6_text_3],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '7',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: -7,
        y: -10,
        imageURL:
          'assets/templates_jal_travel_book/07011/M/07011-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/07011/M/07011-M{{assetSkin}}.pdf',
        depth: 0,
      };

      const patternCountry: ImageElement = {
        type: 'ImageElement',
        x: -11,
        y: -15,
        imageURL:
          'assets/templates_jal_travel_book/07011/C/07011-C{{assetDestination}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/07011/C/07011-C{{assetDestination}}.pdf',
        depth: 1,
      };

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            {
              type: 'AssetElement',
              asset: {
                assetDestination: getDestinationsMapValue(
                  bookData.destinations,
                ),
              },
            },
            patternCountry,
          ),
        ],
      );
      return objects;
    },
    pages: [
      {
        pageId: '7',
        pageImageURL: 'assets/templates_jal_travel_book/07011/07011.png',
        pageImagePDFURL: 'assets/templates_jal_travel_book/07011/07011.pdf',
        text: [jp.SEGMENT_7_text_1, jp.SEGMENT_7_text_2],
        photoPosition: {
          x: 234,
          y: -10.5,
          width: 265,
          height: 357,
          rotation: 18.425,
        },
      },
    ],
  } as TSegment,
  {
    segmentId: '8',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '8',
        pageImageURL: 'assets/templates_jal_travel_book/08012/08012.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/08012/08012.pdf',
        text: [
          jp.SEGMENT_8_text_1,
          jp.SEGMENT_8_text_2_1,
          jp.SEGMENT_8_text_2_2,
          jp.SEGMENT_8_text_3,
          jp.SEGMENT_8_text_4,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '9',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '9',
        pageImageURL: 'assets/templates_jal_travel_book/09013/09013.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/09013/09013.pfg',
        text: [
          jp.SEGMENT_9_text_1,
          jp.SEGMENT_9_text_2,
          jp.SEGMENT_9_text_3,
          jp.SEGMENT_9_text_4,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '10',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 543,
        y: 128,
        imageURL:
          'assets/templates_jal_travel_book/10014/M/10014-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/10014/M/10014-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 543,
        y: 128,
        imageURL:
          'assets/templates_jal_travel_book/10014/H/10014-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/10014/H/10014-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 543,
        y: 128,
        imageURL: 'assets/templates_jal_travel_book/10014/G/10014-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/10014/G/10014-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '10',
        pageImageURL: 'assets/templates_jal_travel_book/10014/10014.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/10014/10014.PDF',
        text: [jp.SEGMENT_10_text_1],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '11',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '11',
        pageImageURL: 'assets/templates_jal_travel_book/11015/11015.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/11015/11015.pdf',
        text: [
          jp.SEGMENT_11_text_1,
          jp.SEGMENT_11_text_2,
          jp.SEGMENT_11_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '12',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;

      if (dest1 === 'Hawaii') {
        patternSkin = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,

          imageURL:
            'assets/templates_jal_travel_book/12016/M/12016-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12016/M/12016-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL:
            'assets/templates_jal_travel_book/12016/H/12016-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12016/H/12016-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL: 'assets/templates_jal_travel_book/12016/G/12016-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/12016/G/12016-G01.pdf',
          depth: 3,
        };
      } else if (dest1 === 'England') {
        patternSkin = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL:
            'assets/templates_jal_travel_book/12017/M/12017-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12017/M/12017-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL:
            'assets/templates_jal_travel_book/12017/H/12017-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12017/H/12017-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL: 'assets/templates_jal_travel_book/12017/G/12017-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/12017/G/12017-G01.pdf',
          depth: 3,
        };
      } else if (dest1 === 'Finland') {
        patternSkin = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL:
            'assets/templates_jal_travel_book/12018/M/12018-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12018/M/12018-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL:
            'assets/templates_jal_travel_book/12018/H/12018-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/12018/H/12018-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: -10,
          y: 108,
          xPdf: -10,
          yPdf: 110,
          imageURL: 'assets/templates_jal_travel_book/12018/G/12018-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/12018/G/12018-G01.pdf',
          depth: 3,
        };
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment12Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '13',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    getPages: (bookData: IBookTravelData) =>
      segment13Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '14',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;

      if (dest1 === 'England') {
        patternSkin = {
          type: 'ImageElement',
          x: 424,
          y: 299,
          imageURL:
            'assets/templates_jal_travel_book/99041/M/99041-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99041/M/99041-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 424,
          y: 299,
          imageURL:
            'assets/templates_jal_travel_book/99041/H/99041-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99041/H/99041-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 424,
          y: 299,
          imageURL: 'assets/templates_jal_travel_book/99041/G/99041-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99041/G/99041-G01.pdf',
          depth: 3,
        };
      } else if (dest1 === 'Finland') {
        patternSkin = {
          type: 'ImageElement',
          x: 424,
          y: 256,
          imageURL:
            'assets/templates_jal_travel_book/99051/M/99051-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99051/M/99051-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 424,
          y: 256,
          imageURL:
            'assets/templates_jal_travel_book/99051/H/99051-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99051/H/99051-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 424,
          y: 256,
          imageURL: 'assets/templates_jal_travel_book/99051/G/99051-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99051/G/99051-G01.pdf',
          depth: 3,
        };
      } else if (dest1 === 'Hawaii') {
        return objects;
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment14Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '15',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;

      if (dest1 === 'Hawaii') {
        patternSkin = {
          type: 'ImageElement',
          x: 391,
          y: 267,
          imageURL:
            'assets/templates_jal_travel_book/99032/M/99032-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99032/M/99032-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 391,
          y: 267,
          imageURL:
            'assets/templates_jal_travel_book/99032/H/99032-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99032/H/99032-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 391,
          y: 267,
          imageURL: 'assets/templates_jal_travel_book/99032/G/99032-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99032/G/99032-G01.pdf',
          depth: 3,
        };
      } else if (dest1 === 'England') {
        return objects;
      } else if (dest1 === 'Finland') {
        return objects;
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    getPages: (bookData: IBookTravelData) =>
      segment15Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '16',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1] = getSortedDestinations(bookData.destinations);
      const strenth = bookData.strength;
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;
      if (dest1 === 'Hawaii') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: 3,
            y: 3,
            imageURL:
              'assets/templates_jal_travel_book/99033/M/99033-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99033/M/99033-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: 3,
            y: 3,
            imageURL:
              'assets/templates_jal_travel_book/99033/H/99033-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99033/H/99033-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: 3,
            y: 3,
            imageURL: 'assets/templates_jal_travel_book/99033/G/99033-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99033/G/99033-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99035/M/99035-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99035/M/99035-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99035/H/99035-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99035/H/99035-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99035/G/99035-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99035/G/99035-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99037/M/99037-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99037/M/99037-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99037/H/99037-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99037/H/99037-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99037/G/99037-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99037/G/99037-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest1 === 'England') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99043/M/99043-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99043/M/99043-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99043/H/99043-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99043/H/99043-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99043/G/99043-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99043/G/99043-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99045/M/99045-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99045/M/99045-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99045/H/99045-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99045/H/99045-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99045/G/99045-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99045/G/99045-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99047/M/99047-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99047/M/99047-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99047/H/99047-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99047/H/99047-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99047/G/99047-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99047/G/99047-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest1 === 'Finland') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99053/M/99053-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99053/M/99053-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99053/H/99053-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99053/H/99053-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99053/G/99053-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99053/G/99053-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99055/M/99055-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99055/M/99055-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99055/H/99055-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99055/H/99055-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99055/G/99055-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99055/G/99055-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99057/M/99057-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99057/M/99057-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99057/H/99057-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99057/H/99057-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99057/G/99057-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99057/G/99057-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      return objects;
    },
    getPages: (bookData: IBookTravelData) =>
      segment16Pages[getSortedDestinations(bookData.destinations)[0]][
        bookData.strength
      ],
    pages: [],
  } as TSegment,
  {
    segmentId: '17',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1, dest2] = getSortedDestinations(bookData.destinations);
      const strenth = bookData.strength;
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;
      if (dest1 === 'Hawaii') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99034/M/99034-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99034/M/99034-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99034/H/99034-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99034/H/99034-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99034/G/99034-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99034/G/99034-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99036/M/99036-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99036/M/99036-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99036/H/99036-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99036/H/99036-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99036/G/99036-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99036/G/99036-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99038/M/99038-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99038/M/99038-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99038/H/99038-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99038/H/99038-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99038/G/99038-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99038/G/99038-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest1 === 'England') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99044/M/99044-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99044/M/99044-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99044/H/99044-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99044/H/99044-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99044/G/99044-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99044/G/99044-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99046/M/99046-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99046/M/99046-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99046/H/99046-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99046/H/99046-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99046/G/99046-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99046/G/99046-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99048/M/99048-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99048/M/99048-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99048/H/99048-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99048/H/99048-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99048/G/99048-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99048/G/99048-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest1 === 'Finland') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99054/M/99054-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99054/M/99054-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99054/H/99054-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99054/H/99054-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99054/G/99054-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99054/G/99054-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99056/M/99056-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99056/M/99056-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99056/H/99056-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99056/H/99056-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99056/G/99056-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99056/G/99056-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99058/M/99058-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99058/M/99058-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99058/H/99058-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99058/H/99058-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99058/G/99058-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99058/G/99058-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      return objects;
    },
    getPages: (bookData: IBookTravelData) =>
      segment17Pages[getSortedDestinations(bookData.destinations)[0]][
        bookData.strength
      ],
    pages: [],
  } as TSegment,
  {
    segmentId: '18',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1] = getSortedDestinations(bookData.destinations);
      let patternStrength: ImageElement;

      if (dest1 === 'England') {
        return objects;
      }
      if (dest1 === 'Finland') {
        patternStrength = {
          type: 'ImageElement',
          x: 528,
          y: 183,
          imageURL:
            'assets/templates_jal_travel_book/99059/S/99059-S{{assetStrength}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99059/S/99059-S{{assetStrength}}.pdf',
          depth: 1,
        };
      } else if (dest1 === 'Hawaii') {
        return objects;
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        resolveAssetElement(strengthConfig[bookData.strength], patternStrength),
      );

      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment18Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '19',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;
      let patternStrength: ImageElement;

      if (dest1 === 'England') {
        patternSkin = {
          type: 'ImageElement',
          x: -6,
          y: -17,
          xPdf: -6,
          yPdf: -13,
          imageURL:
            'assets/templates_jal_travel_book/99050/M/99050-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99050/M/99050-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: -6,
          y: -17,
          xPdf: -6,
          yPdf: -13,
          imageURL:
            'assets/templates_jal_travel_book/99050/H/99050-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99050/H/99050-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: -6,
          y: -17,
          xPdf: -6,
          yPdf: -13,
          imageURL: 'assets/templates_jal_travel_book/99050/G/99050-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99050/G/99050-G01.pdf',
          depth: 3,
        };
      } else if (dest1 === 'Finland') {
        patternSkin = {
          type: 'ImageElement',
          x: 67,
          y: 191,
          imageURL:
            'assets/templates_jal_travel_book/99060/M/99060-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99060/M/99060-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 67,
          y: 191,
          imageURL:
            'assets/templates_jal_travel_book/99060/H/99060-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99060/H/99060-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 67,
          y: 191,
          imageURL: 'assets/templates_jal_travel_book/99060/G/99060-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99060/G/99060-G01.pdf',
          depth: 3,
        };
        patternStrength = {
          type: 'ImageElement',
          x: 0,
          y: 353,
          imageURL:
            'assets/templates_jal_travel_book/99060/S/99060-S{{assetStrength}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99060/S/99060-S{{assetStrength}}.pdf',
          depth: 1,
        };

        objects.push(
          resolveAssetElement(
            strengthConfig[bookData.strength],
            patternStrength,
          ),
        );
      } else if (dest1 === 'Hawaii') {
        patternSkin = {
          type: 'ImageElement',
          x: 3,
          y: -18,
          imageURL:
            'assets/templates_jal_travel_book/99040/M/99040-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99040/M/99040-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 3,
          y: -18,
          imageURL:
            'assets/templates_jal_travel_book/99040/H/99040-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99040/H/99040-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 3,
          y: -18,
          imageURL: 'assets/templates_jal_travel_book/99040/G/99040-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99040/G/99040-G01.pdf',
          depth: 3,
        };
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment19Pages[getSortedDestinations(bookData.destinations)[0]],
    pages: [],
  } as TSegment,
  {
    segmentId: '20',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 302,
        y: 543,
        imageURL:
          'assets/templates_jal_travel_book/20022/M/20022-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/20022/M/20022-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 302,
        y: 543,
        imageURL:
          'assets/templates_jal_travel_book/20022/H/20022-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/20022/H/20022-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 302,
        y: 543,
        imageURL: 'assets/templates_jal_travel_book/20022/G/20022-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/20022/G/20022-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '20',
        pageImageURL: 'assets/templates_jal_travel_book/20022/20022.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/20022/20022.pdf',
        text: [jp.SEGMENT_20_text_1, jp.SEGMENT_20_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '21',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '21',
        pageImageURL: 'assets/templates_jal_travel_book/21023/21023.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/21023/21023.pdf',
        text: [jp.SEGMENT_21_text_1, jp.SEGMENT_21_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '22',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1, dest2] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;

      if (dest2 === 'England') {
        patternSkin = {
          type: 'ImageElement',
          x: 424,
          y: 299,
          imageURL:
            'assets/templates_jal_travel_book/99041/M/99041-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99041/M/99041-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 424,
          y: 299,
          imageURL:
            'assets/templates_jal_travel_book/99041/H/99041-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99041/H/99041-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 424,
          y: 299,
          imageURL: 'assets/templates_jal_travel_book/99041/G/99041-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99041/G/99041-G01.pdf',
          depth: 3,
        };
      } else if (dest2 === 'Finland') {
        patternSkin = {
          type: 'ImageElement',
          x: 424,
          y: 256,
          imageURL:
            'assets/templates_jal_travel_book/99051/M/99051-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99051/M/99051-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 424,
          y: 256,
          imageURL:
            'assets/templates_jal_travel_book/99051/H/99051-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99051/H/99051-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 424,
          y: 256,
          imageURL: 'assets/templates_jal_travel_book/99051/G/99051-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99051/G/99051-G01.pdf',
          depth: 3,
        };
      } else if (dest2 === 'Hawaii') {
        return objects;
      } else if (dest2 === 'India') {
        return objects;
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment22Pages[getSortedDestinations(bookData.destinations)[1]],
    pages: [],
  } as TSegment,
  {
    segmentId: '23',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [dest1, dest2] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;

      if (dest2 === 'England') {
        return objects;
      }
      if (dest2 === 'Finland') {
        return objects;
      }
      if (dest2 === 'Hawaii') {
        return objects;
      }
      if (dest2 === 'India') {
        patternSkin = {
          type: 'ImageElement',
          x: 466,
          y: 298,
          imageURL:
            'assets/templates_jal_travel_book/99062/M/99062-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99062/M/99062-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 466,
          y: 298,
          imageURL:
            'assets/templates_jal_travel_book/99062/H/99062-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99062/H/99062-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 466,
          y: 298,
          imageURL: 'assets/templates_jal_travel_book/99062/G/99062-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99062/G/99062-G01.pdf',
          depth: 3,
        };
      } else {
        throw new Error(`dest1 is ${dest1}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment23Pages[getSortedDestinations(bookData.destinations)[1]],
    pages: [],
  } as TSegment,
  {
    segmentId: '24',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [, dest2] = getSortedDestinations(bookData.destinations);
      const strenth = bookData.strength;
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;
      if (dest2 === 'Hawaii') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99033/M/99033-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99033/M/99033-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99033/H/99033-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99033/H/99033-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99033/G/99033-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99033/G/99033-G01.pdf',
            depth: 3,
          };
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99035/M/99035-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99035/M/99035-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99035/H/99035-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99035/H/99035-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99035/G/99035-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99035/G/99035-G01.pdf',
            depth: 3,
          };
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99037/M/99037-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99037/M/99037-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99037/H/99037-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99037/H/99037-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99037/G/99037-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99037/G/99037-G01.pdf',
            depth: 3,
          };
        }
      } else if (dest2 === 'England') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99043/M/99043-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99043/M/99043-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99043/H/99043-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99043/H/99043-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99043/G/99043-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99043/G/99043-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99045/M/99045-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99045/M/99045-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99045/H/99045-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99045/H/99045-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99045/G/99045-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99045/G/99045-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99047/M/99047-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99047/M/99047-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99047/H/99047-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99047/H/99047-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99047/G/99047-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99047/G/99047-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest2 === 'Finland') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99053/M/99053-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99053/M/99053-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99053/H/99053-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99053/H/99053-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99053/G/99053-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99053/G/99053-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99055/M/99055-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99055/M/99055-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99055/H/99055-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99055/H/99055-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99055/G/99055-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99055/G/99055-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99057/M/99057-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99057/M/99057-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99057/H/99057-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99057/H/99057-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99057/G/99057-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99057/G/99057-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest2 === 'India') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -9,
            y: -11,
            imageURL:
              'assets/templates_jal_travel_book/99063/M/99063-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99063/M/99063-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -9,
            y: -11,
            imageURL:
              'assets/templates_jal_travel_book/99063/H/99063-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99063/H/99063-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -9,
            y: -11,
            imageURL: 'assets/templates_jal_travel_book/99063/G/99063-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99063/G/99063-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99065/M/99065-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99065/M/99065-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99065/H/99065-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99065/H/99065-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99065/G/99065-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99065/G/99065-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99067/M/99067-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99067/M/99067-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99067/H/99067-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99067/H/99067-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99067/G/99067-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99067/G/99067-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else {
        throw new Error(`dest1 is ${dest2}`);
      }

      return objects;
    },
    getPages: (bookData: IBookTravelData) =>
      segment24Pages[getSortedDestinations(bookData.destinations)[1]][
        bookData.strength
      ],
    pages: [],
  } as TSegment,
  {
    segmentId: '25',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [, dest2] = getSortedDestinations(bookData.destinations);
      const strenth = bookData.strength;
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;
      if (dest2 === 'Hawaii') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99034/M/99034-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99034/M/99034-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99034/H/99034-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99034/H/99034-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99034/G/99034-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99034/G/99034-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99036/M/99036-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99036/M/99036-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99036/H/99036-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99036/H/99036-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99036/G/99036-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99036/G/99036-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99038/M/99038-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99038/M/99038-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99038/H/99038-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99038/H/99038-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99038/G/99038-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99038/G/99038-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest2 === 'England') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99044/M/99044-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99044/M/99044-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99044/H/99044-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99044/H/99044-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99044/G/99044-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99044/G/99044-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99046/M/99046-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99046/M/99046-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99046/H/99046-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99046/H/99046-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99046/G/99046-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99046/G/99046-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99048/M/99048-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99048/M/99048-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99048/H/99048-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99048/H/99048-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99048/G/99048-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99048/G/99048-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest2 === 'Finland') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99054/M/99054-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99054/M/99054-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99054/H/99054-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99054/H/99054-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99054/G/99054-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99054/G/99054-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99056/M/99056-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99056/M/99056-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99056/H/99056-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99056/H/99056-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99056/G/99056-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99056/G/99056-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99058/M/99058-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99058/M/99058-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99058/H/99058-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99058/H/99058-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99058/G/99058-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99058/G/99058-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else if (dest2 === 'India') {
        if (strenth === 'kind') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99064/M/99064-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99064/M/99064-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99064/H/99064-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99064/H/99064-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99064/G/99064-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99064/G/99064-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'brave') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99066/M/99066-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99066/M/99066-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99066/H/99066-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99066/H/99066-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99066/G/99066-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99066/G/99066-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        } else if (strenth === 'creative') {
          patternSkin = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99068/M/99068-M{{assetSkin}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99068/M/99068-M{{assetSkin}}.pdf',
            depth: 1,
          };
          patternHair = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL:
              'assets/templates_jal_travel_book/99068/H/99068-H{{assetHair}}.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99068/H/99068-H{{assetHair}}.pdf',
            depth: 2,
          };
          patternGlasses = {
            type: 'ImageElement',
            x: -8,
            y: -8,
            imageURL: 'assets/templates_jal_travel_book/99068/G/99068-G01.png',
            imagePDFURL:
              'assets/templates_jal_travel_book/99068/G/99068-G01.pdf',
            depth: 3,
          };
          objects.push(
            ...[
              resolveAssetElement(
                skinColorConfig[bookData.clothColor][bookData.skinColor],
                patternSkin,
              ),
              resolveAssetElement(
                hairColorConfig[bookData.hairColor][bookData.hairStyle],
                patternHair,
              ),
            ],
          );

          if (bookData.glasses) {
            objects.push(patternGlasses);
          }
        }
      } else {
        throw new Error(`dest2 is ${dest2}`);
      }

      return objects;
    },
    getPages: (bookData: IBookTravelData) =>
      segment25Pages[getSortedDestinations(bookData.destinations)[1]][
        bookData.strength
      ],
    pages: [],
  } as TSegment,
  {
    segmentId: '26',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [, dest2] = getSortedDestinations(bookData.destinations);
      let patternStrength: ImageElement;

      if (dest2 === 'England') {
        return objects;
      }
      if (dest2 === 'Finland') {
        patternStrength = {
          type: 'ImageElement',
          x: 528,
          y: 183,
          imageURL:
            'assets/templates_jal_travel_book/99059/S/99059-S{{assetStrength}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99059/S/99059-S{{assetStrength}}.pdf',
          depth: 1,
        };
      } else if (dest2 === 'Hawaii') {
        return objects;
      } else if (dest2 === 'India') {
        return objects;
      } else {
        throw new Error(`dest1 is ${dest2}`);
      }

      objects.push(
        resolveAssetElement(strengthConfig[bookData.strength], patternStrength),
      );

      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment26Pages[getSortedDestinations(bookData.destinations)[1]],
    pages: [],
  } as TSegment,
  {
    segmentId: '27',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),

    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const [, dest2] = getSortedDestinations(bookData.destinations);
      let patternSkin: ImageElement;
      let patternHair: ImageElement;
      let patternGlasses: ImageElement;
      let patternStrength: ImageElement;

      if (dest2 === 'England') {
        patternSkin = {
          type: 'ImageElement',
          x: -6,
          y: -17,
          xPdf: -6,
          yPdf: -13,
          imageURL:
            'assets/templates_jal_travel_book/99050/M/99050-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99050/M/99050-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: -6,
          y: -17,
          xPdf: -6,
          yPdf: -13,
          imageURL:
            'assets/templates_jal_travel_book/99050/H/99050-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99050/H/99050-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: -6,
          y: -17,
          xPdf: -6,
          yPdf: -13,
          imageURL: 'assets/templates_jal_travel_book/99050/G/99050-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99050/G/99050-G01.pdf',
          depth: 3,
        };
      } else if (dest2 === 'Finland') {
        patternSkin = {
          type: 'ImageElement',
          x: 67,
          y: 191,
          imageURL:
            'assets/templates_jal_travel_book/99060/M/99060-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99060/M/99060-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 67,
          y: 191,
          imageURL:
            'assets/templates_jal_travel_book/99060/H/99060-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99060/H/99060-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 67,
          y: 191,
          imageURL: 'assets/templates_jal_travel_book/99060/G/99060-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99060/G/99060-G01.pdf',
          depth: 3,
        };
        patternStrength = {
          type: 'ImageElement',
          x: 0,
          y: 353,
          imageURL:
            'assets/templates_jal_travel_book/99060/S/99060-S{{assetStrength}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99060/S/99060-S{{assetStrength}}.pdf',
          depth: 1,
        };

        objects.push(
          resolveAssetElement(
            strengthConfig[bookData.strength],
            patternStrength,
          ),
        );
      } else if (dest2 === 'Hawaii') {
        patternSkin = {
          type: 'ImageElement',
          x: 267,
          y: 165,
          imageURL:
            'assets/templates_jal_travel_book/99040/M/99040-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99040/M/99040-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 267,
          y: 165,
          imageURL:
            'assets/templates_jal_travel_book/99040/H/99040-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99040/H/99040-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 267,
          y: 165,
          imageURL: 'assets/templates_jal_travel_book/99040/G/99040-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99040/G/99040-G01.pdf',
          depth: 3,
        };
      } else if (dest2 === 'India') {
        patternSkin = {
          type: 'ImageElement',
          x: 356,
          y: 189,
          imageURL:
            'assets/templates_jal_travel_book/99070/M/99070-M{{assetSkin}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99070/M/99070-M{{assetSkin}}.pdf',
          depth: 1,
        };
        patternHair = {
          type: 'ImageElement',
          x: 356,
          y: 189,
          imageURL:
            'assets/templates_jal_travel_book/99070/H/99070-H{{assetHair}}.png',
          imagePDFURL:
            'assets/templates_jal_travel_book/99070/H/99070-H{{assetHair}}.pdf',
          depth: 2,
        };
        patternGlasses = {
          type: 'ImageElement',
          x: 356,
          y: 189,
          imageURL: 'assets/templates_jal_travel_book/99070/G/99070-G01.png',
          imagePDFURL: 'assets/templates_jal_travel_book/99070/G/99070-G01.pdf',
          depth: 3,
        };
      } else {
        throw new Error(`dest2 is ${dest2}`);
      }

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },

    getPages: (bookData: IBookTravelData) =>
      segment27Pages[getSortedDestinations(bookData.destinations)[1]],
    pages: [],
  } as TSegment,
  {
    segmentId: '28',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '28',
        pageImageURL: 'assets/templates_jal_travel_book/28024/28024.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/28024/28024.pdf',
        text: [jp.SEGMENT_28_text_1, jp.SEGMENT_28_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '29',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 421,
        y: 239,
        imageURL:
          'assets/templates_jal_travel_book/29025/M/29025-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/29025/M/29025-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 421,
        y: 239,
        imageURL:
          'assets/templates_jal_travel_book/29025/H/29025-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/29025/H/29025-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 421,
        y: 239,
        imageURL: 'assets/templates_jal_travel_book/29025/G/29025-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/29025/G/29025-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '29',
        pageImageURL: 'assets/templates_jal_travel_book/29025/29025.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/29025/29025.pdf',
        text: [
          jp.SEGMENT_29_text_1,
          jp.SEGMENT_29_text_2,
          jp.SEGMENT_29_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '30',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 0,
        y: 131,
        imageURL:
          'assets/templates_jal_travel_book/30026/M/30026-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/30026/M/30026-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternCountry: ImageElement = {
        type: 'ImageElement',
        x: 446,
        y: 26,
        imageURL:
          'assets/templates_jal_travel_book/30026/C/30026-C{{assetDestination}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/30026/C/30026-C{{assetDestination}}.pdf',
        depth: 1,
      };

      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            {
              type: 'AssetElement',
              asset: {
                assetDestination: getDestinationsMapValue(
                  bookData.destinations,
                ),
              },
            },
            patternCountry,
          ),
        ],
      );

      return objects;
    },
    pages: [
      {
        pageId: '30',
        pageImageURL: 'assets/templates_jal_travel_book/30026/30026.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/30026/30026.pdf',
        text: [
          jp.SEGMENT_30_text_1,
          jp.SEGMENT_30_text_2,
          jp.SEGMENT_30_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '31',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '31',
        pageImageURL: 'assets/templates_jal_travel_book/31027/31027.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/31027/31027.pdf',
        text: [
          jp.SEGMENT_31_text_1,
          jp.SEGMENT_31_text_2,
          jp.SEGMENT_31_text_3,
        ],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '32',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    pages: [
      {
        pageId: '32',
        pageImageURL: 'assets/templates_jal_travel_book/32028/32028.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/32028/32028.pdf',
        text: [jp.SEGMENT_32_text_1, jp.SEGMENT_32_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '33',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];
      const patternSkin: ImageElement = {
        type: 'ImageElement',
        x: 528,
        y: 39,
        imageURL:
          'assets/templates_jal_travel_book/33029/M/33029-M{{assetSkin}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/33029/M/33029-M{{assetSkin}}.pdf',
        depth: 1,
      };
      const patternHair: ImageElement = {
        type: 'ImageElement',
        x: 528,
        y: 39,
        imageURL:
          'assets/templates_jal_travel_book/33029/H/33029-H{{assetHair}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/33029/H/33029-H{{assetHair}}.pdf',
        depth: 2,
      };
      const patternGlasses: ImageElement = {
        type: 'ImageElement',
        x: 528,
        y: 39,
        imageURL: 'assets/templates_jal_travel_book/33029/G/33029-G01.png',
        imagePDFURL: 'assets/templates_jal_travel_book/33029/G/33029-G01.pdf',
        depth: 3,
      };
      objects.push(
        ...[
          resolveAssetElement(
            skinColorConfig[bookData.clothColor][bookData.skinColor],
            patternSkin,
          ),
          resolveAssetElement(
            hairColorConfig[bookData.hairColor][bookData.hairStyle],
            patternHair,
          ),
        ],
      );

      if (bookData.glasses) {
        objects.push(patternGlasses);
      }
      return objects;
    },
    pages: [
      {
        pageId: '33',
        pageImageURL: 'assets/templates_jal_travel_book/33029/33029.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/33029/33029.pdf',
        text: [jp.SEGMENT_33_text_1, jp.SEGMENT_33_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '34',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];

      const patternCountry: ImageElement = {
        type: 'ImageElement',
        x: 0,
        y: 0,
        imageURL:
          'assets/templates_jal_travel_book/34030/C/34030-C{{assetDestination}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/34030/C/34030-C{{assetDestination}}.pdf',
        depth: 1,
      };

      objects.push(
        resolveAssetElement(
          {
            type: 'AssetElement',
            asset: {
              assetDestination: getDestinationsMapValue(bookData.destinations),
            },
          },
          patternCountry,
        ),
      );

      return objects;
    },
    pages: [
      {
        pageId: '34',
        pageImageURL: 'assets/templates_jal_travel_book/34030/34030.jpg',
        pageImagePDFURL: 'assets/templates_jal_travel_book/34030/34030.pdf',
        text: [jp.SEGMENT_34_text_1, jp.SEGMENT_34_text_2],
        data: {},
      },
    ],
  } as TSegment,
  {
    segmentId: '35',
    textProcessor: (bookData, text) =>
      textProcessor(bookData as IBookTravelData, text),
    objectProcessor: (bookData) => {
      const objects: (ImageElement | TextElement)[] = [];

      const patternCountry: ImageElement = {
        type: 'ImageElement',
        x: 0,
        y: 0,
        imageURL:
          'assets/templates_jal_travel_book/00001/C/00001-C{{assetDestination}}.png',
        imagePDFURL:
          'assets/templates_jal_travel_book/00001/C/00001-C{{assetDestination}}.pdf',
        depth: 1,
      };

      objects.push(
        resolveAssetElement(
          {
            type: 'AssetElement',
            asset: {
              assetDestination: getDestinationsMapValue(bookData.destinations),
            },
          },
          patternCountry,
        ),
      );

      return objects;
    },
    pages: [
      {
        pageId: '35',
        pageImageURL: 'assets/templates_jal_travel_book/00001/00001.jpg',
        text: [jp.SEGMENT_01_text_1],
        data: {},
      },
    ],
  } as TSegment,
] as const;

/**
 * * `segments` usage
 *
 * ```
 * <lib-text-book-view
 *   [segment]="segments[0]"
 *   [debug]="false"
 * ></lib-text-book-view>
 * ```
 */

export default segments;

export * from './_utils';
