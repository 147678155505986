import {
  INPUT_VALUE_COVER_TYPE_SOFT,
  INPUT_VALUE_COVER_TYPE_HARD,
} from '@shared/common/book/constants';
import type { ICover } from '@shared/jp/data/books/types';

interface IGetCoverParams {
  price: ICover['price'];
  carousel: ICover['carousel'];
  // recommended?: ICover['recommended'];
}

export const getCoverSoft = <P extends IGetCoverParams>(params: P) =>
  ({
    id: INPUT_VALUE_COVER_TYPE_SOFT,
    name: 'ソフトカバー',
    summary: /* html */ `小さなお子さまの手でも持ちやすくめくりやすい、文庫本のような柔らかい表紙です。マットな厚紙を表紙に使用しています。`,
    image: 'assets/images/soft.png',
    recommended: false,
    buttonText: 'ソフトカバーを選択する',
    buttonTextActive: 'ソフトカバーを選択中',
    ...params,
  }) as const satisfies ICover;

export const getCoverHard = <P extends IGetCoverParams>(params: P) =>
  ({
    id: INPUT_VALUE_COVER_TYPE_HARD,
    name: 'ハードカバー',
    summary: /* html */ `丈夫で耐久性のある硬い表紙。ギフトや記念にとっておきたい時はこちらがおすすめ！<br>（書店に並ぶ絵本の多くはハードカバーです）`,
    image: 'assets/images/hard.png',
    recommended: true,
    buttonText: 'ハードカバーを選択する',
    buttonTextActive: 'ハードカバーを選択中',
    ...params,
  }) as const satisfies ICover;
