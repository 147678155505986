import type { TFaqData } from '@shared/common/data/faq/types';
import {
  ROUTE_PATH_CONTACTS,
  ROUTE_PATH_INDEX,
} from '@studiobuki/web-core/lib/routing';
import { BASE_HREF } from 'src/app/app.contants';

const link = (href: string, content: string) =>
  /* html */ `<a href="${href}" target="_blank">${content}</a>`;

const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;
const indexHref = `${BASE_HREF}${ROUTE_PATH_INDEX}#preform`;

const faq01 = {
  question: `支払いには何が使えますか？`,
  answer: /* html */ `各種クレジットカードまたはコンビニ払い（ファミリーマート、ローソン、ミニストップ、セイコーマート）がご利用いただけます。コンビニ払いでは、コンビニでのお支払いをもってご注文完了となります。`,
};
const faq02 = {
  question: `いつ届きますか？`,
  answer: /* html */ `ご注文時にウェブサイトに記載があった日付が配達予定日です。（※この日付は、あくまで予定であり、配達を確約するものではありません。当日の天候や交通道路の状況によっては遅れる可能性があります）<br><br>なお、コンビニ払いの場合は、コンビニでのお支払いをもってご注文完了となりますため、最短配達日でお送りするお支払い期限までにお支払いがされなかった場合は、配達日がご希望より遅くなる可能性があります。ご注意ください。<br><br>メールなどでお伝えしていた配達日を過ぎてもお手元に届かない場合は${link(
    contactsHref,
    'お問い合わせページ',
  )}から運営元のSTUDIO BUKI株式会社のカスタマーサポートへお問合せください。`,
};
const faq03 = {
  question: `発送方法は何がありますか？`,
  answer: /* html */ `１冊の場合：メール便（クロネコゆうパケット・ポスト投函）か宅急便（ヤマト運輸・対面受け渡し）をお選びいただけます。<br>２冊～４冊の場合：宅急便（ヤマト運輸・対面受け渡し）でお届けします。<br><br>どちらも追跡番号が発行され、発送時にメールでご案内しております。<br>日時指定は宅急便のみ可能です。宅急便でご注文完了後、ご希望の日時を各種お問い合わせ窓口からご連絡ください。`,
};
const faq04 = {
  question: `送料はいくらかかりますか？`,
  answer: /* html */ `メール便（クロネコゆうパケット）は全国一律<span style="color: red;"><b>420</b>円（税込）</span>です。宅急便（ヤマト運輸）は発送先により異なります、以下↓の料金表をご覧ください。<br><br>  <table>
  <tr>
    <th>発送先</th>
    <th>送料（税込）</th>
  </tr>
  <tr>
    <td>北海道</td>
    <td>1,800円</td>
  </tr>
  <tr>
    <td>北東北</td>
    <td>1,170円</td>
  </tr>
  <tr>
    <td>南東北</td>
    <td>1,050円</td>
  </tr>
  <tr>
    <td>関東</td>
    <td>880円</td>
  </tr>
  <tr>
    <td>信越</td>
    <td>880円</td>
  </tr>
  <tr>
    <td>北陸</td>
    <td>880円</td>
  </tr>
  <tr>
    <td>中部</td>
    <td>880円</td>
  </tr>
  <tr>
    <td>関西</td>
    <td>780円</td>
  </tr>
  <tr>
    <td>中国</td>
    <td>800円</td>
  </tr>
  <tr>
    <td>四国</td>
    <td>880円</td>
  </tr>
  <tr>
    <td>九州</td>
    <td>930円</td>
  </tr>
  <tr>
    <td>沖縄</td>
    <td>1,650円</td>
  </tr>
</table>`,
};

const faq05 = {
  question: `発送を早めることはできますか？`,
  answer: /* html */ `申し訳ございません、印刷工場でのスケジュールが決まっているため、ご案内している最短配達日より早くお届けすることはできません。`,
};
const faq06 = {
  question: `コンビニ払いの手順がわかりません。`,
  answer: /* html */ `コンビニ払いを選択後、お会計の最後の画面にそれぞれのコンビニでのお支払い方法が表示されます。<br>また、同時に送信されるご注文メールでも、コンビニでのお支払い方法を記載しています。<br>記載の方法に従って、コンビニ店頭でお支払いをお願いします。<br>詳しいご案内については、手順や期限をご案内しますので、${link(
    contactsHref,
    'お問い合わせページ',
  )}からお問い合わせください。お急ぎの場合はLINEでお問い合わせください。`,
};
const faq07 = {
  question: `注文後のキャンセル・修正はできますか？`,
  answer: /* html */ `オーダーメイドのためご注文後のキャンセル・修正をすることはできません。お会計前にお子さまの情報に間違い・メッセージに誤字などがないか、ご確認ください。`,
};
const faq08 = {
  question: `コンビニ払いの期限が切れてしまいました。`,
  answer: /* html */ `期限切れになってしまった場合、自動的にいただいていたご注文内容は消去されます。お手数をお掛けして申し訳ございませんが、改めてウェブサイトよりご注文をお願いします。<br>なお、お支払いにあたりご不明な点がございましたら、${link(
    contactsHref,
    'お問い合わせページ',
  )}からお問い合わせください。`,
};
const faq09 = {
  question: `ギフトラッピングはできますか？`,
  answer: /* html */ `申し訳ありません、現在ギフトラッピングのサービスは行っておりません。<br><br>絵本をお届けする段ボールの梱包材にはかわいいキャラクターのイラストが描かれています。お子さまが箱を開けると、キャラクターが胸に絵本を抱えているようなデザインです。<br>絵本をお届けした後は、塗り絵としてもお楽しみいただけます。<br><br>追加で新しい梱包材の送付をご希望の場合は${link(
    contactsHref,
    'お問合せ窓口',
  )}からご連絡をお願い致します。<br><br>（※新しい梱包材をご希望の場合は絵本のお代金とは別で500円をいただいております。クレジットカード払いのみ対応）`,
};
const faq10 = {
  question: `表紙はどんな種類がありますか？`,
  answer: /* html */ `文庫本のような柔らかくめくりやすいソフトカバーと、耐久性のある固いハードカバー（＋税込1,000円）をご用意しています。特に贈り物であれば丈夫なハードカバーがおすすめです。`,
};
const faq11 = {
  question: `どんな紙を使っていますか？`,
  answer: /* html */ `マットでさらりとした手触りと厚みのある、高級紙を使用しています。基準に従って適切に管理された森から生産されたFSC認定取得済みの紙で、地球に優しい取り組みに基づいて作られています。`,
};
const faq12 = {
  question: `注文者とは違う住所に届けることはできますか？`,
  answer: /* html */ `はい。その場合、ご注文者様の情報は「請求先情報」に記入をお願いします。<br>絵本が届かなかった、遅延した、といった絵本に関する情報をお送りすることがございますので、必ずご注文者様の情報の記入をお願いします。<br><br>請求先情報は「お支払いにすすむ」のボタンを押した後に現れる「お客さま情報入力」の画面で、“お届け先と別の情報を入力”を選択すると記入箇所が現れます。<br>ご注文者さまの情報は、配達伝票に「依頼人」として記載されます。`,
};
const faq13 = {
  question: `注文ができているかどうやったら分かりますか？`,
  answer: /* html */ `ご注文が完了している場合①『Thank You！』と書かれたページに遷移し、Aから始まるご注文番号が表示されます。そして同時に注文確認のメールをお送りしています。<br><br>①のページに遷移しなかった場合は、ご決済時に何らかのエラーが起こり、ご注文が完了していない可能性がございます。
  <br><br>①のページは表示されたけれどもメールが届かないという場合は、迷惑メールとして扱われ当ショップからの受信が拒否されている可能性がございます。その場合は  ${link(
    contactsHref,
    'お問い合わせページ',
  )}のLINEやメールからお問合せいただければ、メールを転送致します。`,
};
const faq14 = {
  question: `クレジットカードの情報を入れても注文完了になりません。（何も画面が変わりません）`,
  answer: /* html */ `記入いただいた内容に問題がない場合は数秒後に『Thank You！』と書かれたご注文完了のページに飛びます。<br><br>画面が切り変わらない場合はクレジットカードの内容に記入漏れ、または間違えがあり、決済エラーが起こっています。<br><br>●よくあるお支払エラー<br>①クレジット決済時、CVC（セキュリティーコード）の記入漏れが多発しています。CVCとはカードの裏面にある３～4桁の番号です。有効期限の右側に記入する箇所がございます。<br>②クレジットカードの数字が掠れていて把握しずらく、間違った番号を入れてしまう場合はカードの番号を改めてご確認の上再入力をお願いします。<br>③弊社の決済システムでは利用不可のカードの場合。プリペイドカードや一部のデビットカードは利用が出来ない場合がございます。CVCが記入されてあり、番号も問題がない場合は利用不可のカードの可能性がございます。<br><br>記入が必要な項目がすべて埋められているのを確認の上、あらためて「決済を完了する」のボタンを押してください。<br>何度も「決済を完了する」を押していてご注文が重複していないかご心配の場合は、${link(
    contactsHref,
    'お問い合わせページ',
  )}からご連絡ください。<br><br>なお、その他の理由でお支払エラーが起こっている場合（クレジットカード会社による支払い拒否の場合）は、弊社ではその理由はわかりませんので、カード会社にお問合せください。`,
};
const faq15 = {
  question: `注文内容を確認したいです。`,
  answer: /* html */ `ご注文完了後にお送りしているメールに、ご注文いただいた絵本の内容を記載しています。<br>メールが届かない場合は、恐れ入りますが${link(
    contactsHref,
    'お問い合わせページ',
  )}に記載のLINE窓口もしくはお電話でご連絡ください。`,
};
const faq16 = {
  question: `注文メールが届きません。`,
  answer: /* html */ `現在、キャリア系のメールアドレス（特に@i.softbank.jpや@au.com や @ezweb.ne.jp）をご利用のお客さまから「注文メールが届かない」とのお問い合わせが多く発生しております。設定等によりこちらからのメールが迷惑メールとして扱われている場合があります。<br><br>注文メールが届かないお客さまは、恐れ入りますが${link(
    contactsHref,
    'お問い合わせページ',
  )}に記載のLINE窓口もしくはお電話でご連絡ください。<br><br>なお、docomoをご利用で”パソコンメール受信拒否”に設定している場合は、設定の変更をお願いします。`,
};
const faq17 = {
  question: `領収書を発行できますか？`,
  answer: /* html */ `ギフトとしてお送りいただく場合もあるため、商品には金額がわかる明細書や領収書を同封しておりません。領収書が必要な場合は、お手数ですが${link(
    contactsHref,
    'お問い合わせページ',
  )}から【ご注文番号】をお知らせください。`,
};
const faq18 = {
  question: `届いた絵本に誤字・誤植がありました。`,
  answer: /* html */ `${link(
    contactsHref,
    'お問い合わせページ',
  )}から運営元のSTUDIO BUKI株式会社のカスタマサポートまでご連絡をお願いします。新しいものと交換いたします。<br><br>なお、メッセージ等、お客様のご入力間違いによる誤字は交換の対象外です。ご購入前にご登録いただいた内容に間違いがないかご確認をお願い致します。`,
};
const faq19 = {
  question: `たくさん注文したいです。`,
  answer: /* html */ `一度のご注文で上限を4冊とさせていただいています。5冊以上ご注文の場合は、恐れ入りますがご注文を分けて入力ください。`,
};
const faq20 = {
  question: `海外に発送はできますか？ Do you ship overseas?`,
  answer: /* html */ `申し訳ございません、現在日本国内のみの発送とさせていただいています。<br>Sorry, currently we only ship within Japan. No international shipping.`,
};
const faq21 = {
  question: `商品を受け取ることができませんでした。`,
  answer: /* html */ `ご不在が長く続いた場合や、住所の入力間違いで商品を受け取ることができなかった場合、商品は運営元のSTUDIO BUKI株式会社に返送されます。その場合は着払いにてお客さまご指定のご住所に再送させていただきます。`,
};
const faq22 = {
  question: `入力した送り先が間違っていました / 発送先を変更したい。`,
  answer: /* html */ `${link(
    contactsHref,
    'お問い合わせページ',
  )}から正しいご住所をお知らせください。<br>既に発送の手配が済んでいた場合は、正しいご住所に発送しなおすために、新たに発送代金（宅急便）が発生します。お会計を完了する前に今一度ご入力のご住所にお間違いが無いかご確認をお願いします。`,
};
const faq23 = {
  question: `メール便（クロネコゆうパケット）を利用したいがポストに入るかわかりません。`,
  answer: /* html */ `絵本をお送りする箱は、およそA4サイズ、厚さは約3センチ（１冊の場合）の段ボールです。<br>メール便（クロネコゆうパケット）はポストに投函されますが、ポストに入らなかった場合は、配達員さんの判断で担当の営業店に持ち帰り・再配達になる場合があります。その際は、予定より受け取りが遅くなることがありますのでご注意ください。`,
};
const faq24 = {
  question: `写真をアップロードできません。`,
  answer: /* html */ `写真はJPEG、PNGもしくはHEICの形式で、100KB以上20MB以下のものを使用できます。アップロード後、反映までに20秒ほどかかりますので、お待ちいただきますようお願いします。<br>反映までに長く時間がかかる場合は、通信環境の良いところで再度お試しください。`,
};
const faq25 = {
  question: `営業時間外でもご連絡してもよいでしょうか？`,
  answer: /* html */ `カスタマーサポートの営業時間は平日10：00～16：00となります。<br>それ以外の時間帯でのお問い合わせに関しては、適宜確認し、急を要するお問合せに関しては返信させていただく場合もございます。<br><br>基本的には営業時間外の対応は行っておりませんので、お急ぎの内容でない場合は平日の10:00~16:00にご連絡をお願いします。`,
};
const faq26 = {
  question: `購入したギフトコードはどうやったらわかりますか？`,
  answer: /* html */ `お支払い完了後に送信されるご注文完了メールに（クレジットカードでお支払いの場合は、お支払い完了後の画面にも）ギフトコードを記載しています。
  <br><br>メールが届いていない場合は、恐れ入りますが${link(
    contactsHref,
    'お問い合わせページ',
  )}に記載のLINE窓口もしくはお電話でご連絡ください。`,
};
const faq27 = {
  question: `ギフトコードを相手に渡すにはどうしたらいいですか？`,
  answer: /* html */ `お客さまご自身で、ご注文後にメールで送られたギフトコードやリンク先を、相手の方にメールやLINE、SNS等でお知らせください。<br><br>ギフトコードが印刷されたカードがお手元に届くことはありませんので、ご注意ください。`,
};
const faq28 = {
  question: `ギフトコードの購入前に絵本の内容を確認できますか？`,
  answer: /* html */ `${link(
    indexHref,
    'こちら',
  )}から仮のお子さまのお名前等をご入力いただければ、絵本の試し読みができます。`,
};
const faq29 = {
  question: `ギフトコード利用の場合、絵本の発送・送料はどうなりますか？`,
  answer: /* html */ `ギフトコードには、全て送料込みのお値段でご案内しております。なお、発送方法はポスト投函・日時指定なし・追跡ありのメール便（クロネコゆうパケット）のみとなります。
<br><br>ギフトコードを受け取った方がお手続きを終了後、最短で（概ね１週間以内）発送しております。`,
};
const faq30 = {
  question: `購入したギフトコードはキャンセルできますか？`,
  answer: /* html */ `申し訳ありません、ご購入後のキャンセル・返金には対応できません。`,
};
const faq31 = {
  question: `ギフトコードに有効期限はありますか？`,
  answer: /* html */ `ご購入後の有効期限は１年間です。<br>未使用のまま期限を過ぎた場合は、${link(
    contactsHref,
    'お問い合わせページ',
  )}からご連絡お願いします。新しいギフトコードを発行いたします。`,
};
const faq32 = {
  question: `複数のギフトコードを購入したいです。`,
  answer: /* html */ `申し訳ございません、現在は１回のご注文で１冊分のギフトコードをご購入いただけます。複数のギフトコードをお求めの場合は、恐れ入りますがご注文を分けてお手続きお願いします。`,
};

export const faqData: TFaqData = [
  faq01,
  faq02,
  faq03,
  faq04,
  faq05,
  faq06,
  faq07,
  faq08,
  faq09,
  faq10,
  faq11,
  faq12,
  faq13,
  faq14,
  faq15,
  faq16,
  faq17,
  faq18,
  faq19,
  faq20,
  faq21,
  faq22,
  faq23,
  faq24,
  faq25,
  faq26,
  faq27,
  faq28,
  faq29,
  faq30,
  faq31,
  faq32,
];

export const faqDataCondensed: TFaqData = faqData.slice(0, 4);
