import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageTitleModule } from '@studiobuki/web-core/lib/image-title';
import { SafePipeModule } from 'safe-pipe';
import { SectionReviewsYotpoCommonComponent } from './components/section-reviews-yotpo-common/section-reviews-yotpo-common.component';
import { SectionReviewsYotpoBookComponent } from './components/section-reviews-yotpo-book/section-reviews-yotpo-book.component';

@NgModule({
  declarations: [
    SectionReviewsYotpoCommonComponent,
    SectionReviewsYotpoBookComponent,
  ],
  imports: [CommonModule, ImageTitleModule, SafePipeModule],
  exports: [
    SectionReviewsYotpoCommonComponent,
    SectionReviewsYotpoBookComponent,
  ],
})
export class YotpoModule {}
