import { Inject, Injectable } from '@angular/core';
import { YOTPO_APP_KEY } from './yotpo.tokens';
import { isYotpo } from './yotpo.utils';

@Injectable({
  providedIn: 'root',
})
export class YotpoService {
  private _loading = false;

  constructor(@Inject(YOTPO_APP_KEY) private _appKey: string) {}

  init() {
    if (
      'yotpoWidgetsContainer' in window &&
      isYotpo(window.yotpoWidgetsContainer)
    ) {
      window.yotpoWidgetsContainer.initWidgets(false);
    } else {
      if (this._loading) return;

      this._loading = true;

      const e = document.createElement('script');

      e.type = 'text/javascript';
      e.async = true;
      e.src = `//cdn-widgetsrepository.yotpo.com/v1/loader/${this._appKey}`;
      e.onload = () => {
        this._loading = false;
      };

      const t = document.getElementsByTagName('script')[0];

      // @ts-ignore
      t.parentNode.insertBefore(e, t);
    }
  }
}
