import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBook } from '@shared/jp/data/books/types';
// import { Logger } from '@shared/common/logger';
import { MediaObserver } from '@angular/flex-layout';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { getBooksDiscount } from '@shared/jp/discount/utils';
import { scrollToSelector } from 'src/utils';
import { Logger } from '@shared/common/logger';
import { ALIAS_TO_ID_MAP } from '@shared/jp/book/maps';
import type {
  IBookInputNameToValueMap,
  TBookInput,
} from '@shared/common/data/books/inputs/types';
import type { ICommonBookData } from '@shared/jp/models';
// import { GATrack, GAGetEvents } from 'src/app/analytics';
import { BooksService } from 'src/app/shared/book-page-common/services/books.service';
import { Router } from '@angular/router';
import { faqDataCondensed } from 'src/app/data/faq/data';
import { ROUTE_PATH_FAQ } from '@studiobuki/web-core/lib/routing';
import { stepsData } from 'src/app/data/steps/data';
import type { TFeaturesData } from '@studiobuki/web-core/lib/section-features';

const log = new Logger('Preview -> SectionDetailsComponent');

// const giftHref = `${BASE_HREF}${ROUTE_PATH_GIFT_BUY}`;

@Component({
  selector: 'app-section-details',
  templateUrl: './section-details.component.html',
  styleUrls: ['./section-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionDetailsComponent {
  @Input() book!: IBook;

  public readonly faqDataCondensed = faqDataCondensed;

  public readonly getBooksDiscount = getBooksDiscount;

  public readonly getErrorText = (invalidInput: TBookInput) =>
    // `TODO: Something went wrong with ${invalidInput.label}`;
    `[※主人公の名前を入力してください]`;

  // public readonly customPoints: TBookPoints = [
  //   {
  //     caption: '手軽に絵本を贈りたい（ギフトコード）',
  //     description: /* html */ `ギフトコード（お仕立券）をご利用いただけます。詳しくは<a href="${giftHref}">こちら</a><br/><br/><a href="#reviews">レビューを読む</a>`,
  //     type: EBookPointType.additional,
  //   },
  // ];

  public readonly stepsData = stepsData;

  public readonly featuresData: TFeaturesData = [
    {
      imgSrc: 'assets/images/feature1.png',
      text: ['世界に一冊の', '特別な絵本'],
    },
    {
      imgSrc: 'assets/images/feature2.png',
      text: ['送料 ¥420 (メール便)', '¥770～ (宅急便)'],
    },
    {
      imgSrc: 'assets/images/feature3.png',
      text: ['環境に配慮した', 'FSC認証紙を使用'],
    },
    {
      imgSrc: 'assets/images/feature4.png',
      text: ['お誕生日などの', 'プレゼントに'],
    },
  ];

  constructor(
    public media: MediaObserver,
    public discountService: DiscountService,
    private _router: Router,
    private _booksService: BooksService,
  ) {}

  public async submitted(value: IBookInputNameToValueMap) {
    log.info('submitted', value);
    // const { alias, gift } = this;

    const common: Partial<ICommonBookData> = {};

    // if (gift) {
    //   common.gift = gift;

    //   const userGift = await this._firebaseService.getUserGift(gift);

    //   if (checkUserGift(userGift)) {
    //     common.cover = userGift.book.cover;
    //   }
    // }

    const newBookId = await this._booksService.createBookByAlias(
      this.book.alias,
      value,
      common,
    );

    log.info('created book', newBookId);

    await this._router.navigate(['book', newBookId], {
      // replaceUrl: true,
      // queryParams: {
      //   [ROUTE_QUERY_PARAM_GIFT]: gift,
      // },
    });

    log.info('submit', /* alias, */ value);

    const bookAliasId = ALIAS_TO_ID_MAP[this.book.alias];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!bookAliasId) {
      log.error(`didn't manage to get bookAliasId by alias`, {
        alias: this.book.alias,
        bookAliasId,
      });
    }

    // GATrack(GAGetEvents.getBookPreview(bookAliasId));
  }

  onNoticeButtonClick() {
    scrollToSelector('app-section-banner-mermaid');
  }

  onFaqButtonClick() {
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }
}
