import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import type { IShipping } from '@shared/jp/data/shipping/types';
import type { IOrderData } from '@shared/jp/interfaces';
import type { TCouponData } from '@shared/common/interfaces';
import { EFormPaymentType } from '@shared/jp/interfaces';
import { SHIPPING_METHOD_PREMIUM } from '@shared/common/shipping/constants';
import { REGION_NAME_ENGLISH_DEFAULT } from '@shared/jp/shipping/constants';
import type { TShippingMethod } from '@shared/jp/shipping/interfaces';
import { getFullName, getError } from '@shared/common/utils';
import { DialogsService } from '@studiobuki/web-core/lib/dialogs';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoaderService, ELoaderType } from '@studiobuki/web-core/lib/loader';
import { OrderService } from '@studiobuki/web-core/lib/order';
import { ProductionService } from 'src/app/services/production.service';
import {
  StripeService,
  checkIsKonbiniPayment,
} from '@studiobuki/web-core/lib/stripe';
import { UserService } from '@studiobuki/web-core/lib/user';
import isEqual from 'lodash/isEqual';
import {
  BehaviorSubject,
  filter,
  combineLatest,
  map,
  distinctUntilChanged,
  switchMap,
  take,
  firstValueFrom,
} from 'rxjs';
import { RoutingService } from 'src/app/services/routing.service';
import { MediaObserver } from '@angular/flex-layout';
import Subscriber from '@shared/common/subscriber';
import { Logger } from '@shared/common/logger';
import { getPaymentInformation } from 'src/utils';
import {
  GATrack,
  GAGetEvents,
  sendConversionEventByOrder,
  FBTrack,
  FBEvents,
} from 'src/analytics';
import type { TBookAlias } from '@shared/jp/book/interfaces';
import type { TBookData } from '@shared/jp/models';
import type { IFormData, TStepId } from './interfaces';
import { MainComponent } from './components/main/main.component';

const log = new Logger('OrderComponent');
const sub = new Subscriber();

@Component({
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss'],
})
export class OrderPageComponent implements OnInit, OnDestroy {
  @ViewChild(MainComponent) mainComponent?: MainComponent;

  private _order$ = new BehaviorSubject<IOrderData | undefined>(undefined);

  public order$ = this._order$.pipe(filter((_): _ is IOrderData => !!_));

  public coupon$ = new BehaviorSubject<TCouponData | undefined>(undefined);

  private _formData$ = new BehaviorSubject<IFormData>({
    formEmail: undefined,
    formBillingAddress: undefined,
    formShippingAddress: undefined,
    formSameBillingAddress: true,
    formShippingMethod: undefined,
    formPayment: undefined,
  });
  // public formData: FormData

  get formData() {
    return this._formData$.value;
  }

  set formData(formData: IFormData) {
    const prevFormData = this._formData$.value;

    this._formData$.next(formData);

    if (formData.formBillingAddress !== prevFormData.formBillingAddress) {
      this._onCustomerInfoUpdate(formData.formBillingAddress);
    }

    if (formData.formShippingMethod !== prevFormData.formShippingMethod) {
      this._onShippingMethodUpdate(formData.formShippingMethod);
    }

    if (formData.formShippingAddress !== prevFormData.formShippingAddress) {
      this._onShippingAdreessUpdate(formData.formShippingAddress);
    }
  }

  public disabledShippingMethods: TShippingMethod[] = [];

  /** selected shipping */
  public shippings$ = this._productionService
    .getAllShippings$(
      combineLatest([this.order$, this._formData$]).pipe(
        map(([orderData, formData]) => {
          const regionName = formData.formShippingAddress?.state;

          return {
            booksCount: orderData.books.length,
            regionName: regionName || REGION_NAME_ENGLISH_DEFAULT,
          };
        }),
        distinctUntilChanged(isEqual),
      ),
    )
    .pipe(
      map(({ shippings, disabledShippingMethods }) => {
        this.disabledShippingMethods = disabledShippingMethods;

        return shippings;
      }),
    );

  public shippingMethod$ = new BehaviorSubject<TShippingMethod>(
    SHIPPING_METHOD_PREMIUM,
  );

  public shipping$ = this.shippingMethod$.pipe(
    switchMap((method) =>
      this.shippings$.pipe(
        map((shippings) => shippings.find(({ id }) => id === method)),
      ),
    ),
    filter((method): method is IShipping => !!method),
  );

  private _step: TStepId = 1;

  public readonly steps = 3;

  set step(step: TStepId) {
    const { steps } = this;

    // min max check including thankyou step
    if (step > 0 && step <= steps + 1) {
      this._step = step;

      // not including thankyou step
      if (step <= steps) {
        GATrack(GAGetEvents.getCheckoutStep(step));
      } else {
        this.order$.pipe(take(1)).subscribe((orderData) => {
          sendConversionEventByOrder(orderData);
          this.loaderService.hide();
        });
      }
    }

    document.documentElement.scrollTop = 0;
  }

  get step() {
    return this._step;
  }

  get isCompleted(): boolean {
    return this.step > this.steps;
  }

  // get isBackDisabled(): boolean {
  //   return false;
  // }

  public isBackDisabled = false;

  public isNextDisabled = false;

  constructor(
    private _productionService: ProductionService,
    private orderService: OrderService,
    public userService: UserService<TBookAlias, TBookData>,
    private route: ActivatedRoute,
    private router: Router,
    public media: MediaObserver,
    public stripeService: StripeService,
    public loaderService: LoaderService,
    private _discountService: DiscountService,
    private _dialogsService: DialogsService,
    private _routing: RoutingService,
    private _firebaseService: FirebaseService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loaderService.show();

    const orderId = this.route.snapshot.paramMap.get('orderId');

    if (!orderId) {
      alert(`Didn't manage to get orderId from paramMap!`);
      return;
    }

    sub.push(
      this._firebaseService.getUserOrder$(orderId).subscribe((order) => {
        if (!order) {
          log.error('!order');
          return;
        }

        const isFirstLoad = !this._order$.value;

        log.info('order updated', order);
        this._order$.next(order);
        this.stripeService.currentOrderId = orderId;

        const { error, barOrderId } = order;

        if (error) {
          alert(error.message || error);
          this._routing.goToIndex();
          this.loaderService.hide();
          return;
        }

        // check if order is already paid
        if (barOrderId) {
          log.info('got barOrderId', barOrderId, new Date());
          this.step = 4;
          this.loaderService.hide();

          return;
        }

        if (isFirstLoad) {
          this.loaderService.hide();
          this.step = 1;
        }
      }),
      this._firebaseService.getStripeCoupon$(orderId).subscribe((coupon) => {
        this.coupon$.next(coupon);
      }),
      this._firebaseService
        .getStripePayment$(orderId)
        .subscribe(async (payment) => {
          if (payment && 'error' in payment) {
            log.error('paymentUpdate: error', new Date(), payment);
            // alert(payment.error)
            alert('お支払いにエラーが発生しました。');
            await this.router.navigate(['/cart']);
            this.loaderService.hide();
          }
        }),
      this.stripeService.paymentActionHandle.subscribe(async () => {
        // const payment = this.stripeService.currentOrderIdPayment;
        const payment = await this._firebaseService.getStripePayment(orderId);

        if (payment && checkIsKonbiniPayment(payment)) {
          this.step = 4;
          this.loaderService.hide();
        }
      }),
    );

    FBTrack(FBEvents.initiateCheckout);
  }

  ngOnDestroy(): void {
    sub.unsubscribe();

    this.stripeService.currentOrderId = undefined;
  }

  public onChange(data: IFormData) {
    this.formData = data;
    this.isNextDisabled = false;
  }

  private _onCustomerInfoUpdate(
    formBillingAddress?: IFormData['formBillingAddress'],
  ): void {
    log.info('_onCustomerInfoUpdate', formBillingAddress);
  }

  private _onShippingMethodUpdate(
    formShippingMethod?: IFormData['formShippingMethod'],
  ): void {
    log.info('_onShippingMethodUpdate', formShippingMethod);

    if (formShippingMethod) {
      this.shippingMethod$.next(formShippingMethod);
    }
  }

  private _onShippingAdreessUpdate(
    formShippingAddress?: IFormData['formShippingAddress'],
  ): void {
    log.info('_onShippingAdreessUpdate', formShippingAddress);
  }

  // private _getCurrentRegion(): IRegion {
  //   const { formBillingAddress, formShippingAddress } = this.formData;
  //   const i1 = parseInt(formShippingAddress?.addressFirst || '', 10);
  //   const i2 = parseInt(formBillingAddress?.addressFirst || '', 10);

  //   return Regions[i1 || i2 || 0];
  // }

  public backClick() {
    if (this.step === 1) {
      this.router.navigate(['/cart']);
    } else {
      this.step--;
    }

    log.info('backClick', this.step);
  }

  public async nextClick() {
    log.info('nextClick: step', this.step);

    const isValid = this._validateStep();

    if (!isValid) {
      this.isNextDisabled = true;
      return;
    }

    if (this.step === this.steps) {
      const { formPayment } = this.formData;

      if (!formPayment) {
        alert('formPayment not found!');
        return;
      }

      const emit = async () => {
        this.loaderService.show({ type: ELoaderType.payment, data: {} });
        await this.updateOrderData();
        await this.pay();
      };

      if (formPayment.type === EFormPaymentType.konbini) {
        const result = await firstValueFrom(
          this._dialogsService.showKonbiniConfirmation().afterClosed(),
        );

        log.info(`nextClick: dialog result: ${result}`);

        if (!result) return;
      }

      await emit();
    } else {
      this.step++;
    }
  }

  private async pay() {
    log.info('pay', new Date());

    const {
      formEmail,
      formPayment,
      formShippingAddress,
      formBillingAddress,
      formSameBillingAddress,
    } = this.formData;

    const _formBillingAddress =
      !formSameBillingAddress && formBillingAddress
        ? formBillingAddress
        : formShippingAddress;

    if (!formEmail || !formPayment || !_formBillingAddress) {
      alert('!formEmail || !formPayment || !formShippingAddress');
      return;
    }

    const { type } = formPayment;
    const { orderId } = await firstValueFrom(this.order$);

    try {
      switch (type) {
        case EFormPaymentType.konbini: {
          const { email } = formEmail;
          const { firstName, lastName, phone } = _formBillingAddress;

          await this.stripeService.payWithKonbini(
            orderId,
            getFullName(lastName, firstName),
            email,
            `${phone.replace(/\D/g, '')}`,
          );

          break;
        }

        case EFormPaymentType.card: {
          const { card, name } = formPayment;

          await this.stripeService.payWithCard(orderId, card, name);

          break;
        }

        // no default
      }
    } catch (error) {
      log.error(error);

      // alert(
      //   error?.message ?
      //     error.message :
      //     error
      // )

      alert('お支払いにエラーが発生しました。');

      this.loaderService.hide();
    }
  }

  private async updateOrderData() {
    const orderData = await firstValueFrom(this.order$);
    const shipping = await firstValueFrom(this.shipping$);

    const {
      formEmail,
      formBillingAddress,
      formSameBillingAddress,
      formShippingAddress,
      formPayment,
    } = this.formData;

    if (!formEmail)
      throw getError('missing required parameters', { formEmail });

    // local replacing
    if (formShippingAddress) {
      orderData.addressShippingData = {
        ...formShippingAddress,
        email: formEmail.email,
      };
    }

    // check if the tick 'Ship to the address above' is active
    orderData.addressBillingData =
      !formSameBillingAddress && formBillingAddress
        ? {
            ...formBillingAddress,
            email: formEmail.email,
          }
        : orderData.addressShippingData;

    // const fSM = fD.formShippingMethod

    orderData.shippingMethod = shipping.id;

    const couponData = await this._firebaseService.getStripeCoupon(
      orderData.orderId,
    );

    const discountCampaign = await firstValueFrom(
      this._discountService.activeDiscountCampaign$,
    );

    if (formPayment?.type !== undefined) {
      orderData.paymentInformation = getPaymentInformation(
        orderData.books,
        orderData.shippingMethod,
        shipping.price,
        formPayment.type,
        couponData,
        discountCampaign,
      );
    }

    this._order$.next(orderData);
    // saving on the BE side
    // TODO: remove ignore
    // @ts-ignore
    await this.orderService.updateOrder(orderData.orderId, orderData);
  }

  /**
   * @returns `true` if valid
   * @returns `false` and touches all the inputs to show up errors if not
   */
  private _validateStep(): boolean {
    const { mainComponent, step } = this;

    if (!mainComponent) return false;

    let status = false;

    switch (step) {
      case 1: {
        const {
          formEmailComponent,
          formBillingAddressComponent,
          formShippingAddressComponent,
        } = mainComponent;

        if (!formEmailComponent || !formShippingAddressComponent) {
          // set status
          status = false;

          break;
        }

        const isFormEmailValid = formEmailComponent.formGroup.valid;

        const isFormShippingAddressValid =
          formShippingAddressComponent.formGroup.valid;

        const isFormBillingAddressValid = formBillingAddressComponent
          ? formBillingAddressComponent.formGroup.valid
          : true;

        // set status
        status =
          isFormEmailValid &&
          isFormShippingAddressValid &&
          isFormBillingAddressValid;

        // touch if not valid to show errors
        if (!status) {
          formEmailComponent.touch();
          formShippingAddressComponent.touch();
          formBillingAddressComponent?.touch();
        }

        break;
      }
      case 2:
        status = true;

        break;
      case 3: {
        const { formPaymentComponent } = mainComponent;

        if (!formPaymentComponent) {
          // set status
          status = false;

          break;
        }

        const { formGroup } = formPaymentComponent;

        if (!formGroup) {
          log.error('formGroup is not defined in', formPaymentComponent);
          return status;
        }

        const isFormPaymentValid = formGroup.valid;

        // set status
        status = isFormPaymentValid;

        // touch if not valid to show errors
        if (!status) {
          formGroup.markAllAsTouched();
          Object.values(formGroup.controls).forEach((control) => {
            control.markAsDirty();
          });
        }

        break;
      }
      // no default
    }

    return status;
  }
}
