import type { TSectionSteps } from '@studiobuki/web-core/lib/section-steps';

export const stepsData: TSectionSteps = [
  {
    imgSrc: 'assets/images/step1.png',
    text: [
      'お子さまの名前を入力、',
      'また髪型などを選択します。',
      'すると！絵本のできあがりです。',
    ],
  },
  {
    imgSrc: 'assets/images/step2.png',
    text: [
      '試し読みをしながらメッセージや写真などを加えてカスタマイズ。',
      'さらに特別な一冊に。',
    ],
  },
  {
    imgSrc: 'assets/images/step3.png',
    text: ['ご注文後一冊ずつ印刷・製本して', 'お届けします！'],
  },
];
