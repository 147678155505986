import type { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { YotpoService } from 'src/app/shared/yotpo/yotpo.service';

@Component({
  templateUrl: './reviews-page.component.html',
  styleUrls: ['./reviews-page.component.scss'],
})
export class ReviewsPageComponent implements AfterViewInit {
  constructor(private _yotpo: YotpoService) {}

  ngAfterViewInit(): void {
    this._yotpo.init();
  }
}
