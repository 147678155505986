<div class="wrap">
  <app-section-header-gap
    class="wrap__section-header-gap"
    *ngIf="media.isLtXl$ | async"
  ></app-section-header-gap>
  <app-section-about-banner></app-section-about-banner>
  <div class="wrap__section-description">
    <div class="description">
      <p class="description__passage">
        私たちJALグループは、長年にわたり航空輸送を通じて<br />
        旅の機会を提供してまいりました。
      </p>
      <p class="description__passage">
        旅は新しい経験や発見をもたらし、人生を豊かにしてくれると考えております。
      </p>
      <p class="description__passage">
        そんな旅の魅力と「世界はとても広く、あなたらしさを大切に育んでほしい。<br />
        大丈夫だよ」というメッセージが込められる特別な絵本を制作いたしました。
      </p>
      <p class="description__passage">
        物語を通して、新たな発見や驚き、感動を経験いただけることを願っています。<br />
        そのワクワクが、未知の世界へと飛び立つ勇気となるように。
      </p>
      <br />
      <br />
      <p class="description__highlighted-passage">
        子どもたちの心の中で広がる世界が、希望と夢に満ちた世界を見つけられるようにと願いを込めて、一冊一冊の絵本をお届けします。
      </p>
    </div>
  </div>
  <div class="wrap__section-photo">
    <picture class="photo">
      <source
        media="(max-width: 767px)"
        srcset="assets/images/about-page-review-photo.png"
      />
      <img
        src="assets/images/about-page-review-photo.png"
        alt="Banner background image."
      />
    </picture>
  </div>
  <div class="wrap__section-gift">
    <app-gift-section></app-gift-section>
  </div>
  <div class="airplane_image">
    <img
      src="assets/images/about_airplane.jpg"
      alt="airplane image"
    />
  </div>
  <!--  <div class="wrap__section-message">-->
  <!--    <app-section-message></app-section-message>-->
  <!--  </div>-->
  <!--  <div class="wrap__section-collage">-->
  <!--    <picture class="collage">-->
  <!--      <source-->
  <!--        media="(max-width: 767px)"-->
  <!--        srcset="assets/images/collage_mobile.jpg"-->
  <!--      />-->
  <!--      <img-->
  <!--        src="assets/images/collage.jpg"-->
  <!--        alt="Banner background image."-->
  <!--      />-->
  <!--    </picture>-->
  <!--  </div>-->
  <!--  <div class="wrap__section-story">-->
  <!--    <app-section-story></app-section-story>-->
  <!--  </div>-->
  <!--  <div class="wrap__section-business-card">-->
  <!--    <app-section-business-card></app-section-business-card>-->
  <!--  </div>-->
</div>
