<div class="wrap">
  <div class="wrap__container">
    <div class="container">
      <div class="container__title">
        <lib-image-title type="reviews">{{ title }}</lib-image-title>
      </div>
      <div class="container__content">
        <!-- old version -->
        <!-- <div
          class="yotpo yotpo-reviews-carousel"
          data-header-customisation-enabled="1"
          data-header-customisation-color="#56575f"
          data-header-customisation-font-size="18"
          [attr.data-header-customisation-text]="title"
          data-header-customisation-alignment="left"
          data-background-color="transparent"
          data-mode="most_recent"
          data-type="product"
          data-count="9"
          data-show-bottomline="1"
          data-autoplay-enabled="1"
          data-autoplay-speed="4500"
          data-show-navigation="1"
        >
          &nbsp;
        </div> -->
        <div
          class="yotpo-widget-instance"
          data-yotpo-instance-id="967571"
        ></div>
      </div>
    </div>
  </div>
  <i
    *ngIf="showMonster"
    class="wrap__monster"
  ></i>
</div>
